// frontend/src/components/properties-sidebar/DisplayOptions.js
import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Dropdown, Button, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getRelevantTokens } from './utils';

const DisplayOptions = ({
  selectedComponentInfo,
  globalTokens,
  editedTokens,
  designTokens,
  tokenNames,
  handleTokenChange,
  setEditedTokens,
  setTokenNames,
  viewportSize,
}) => {
  if (!selectedComponentInfo || !selectedComponentInfo.id) return null;

  const componentId = selectedComponentInfo.id;

  const displayValues = ['block', 'inline', 'flex', 'inline-flex', 'grid', 'inline-grid', 'none'];

  const [localValues, setLocalValues] = useState({});

  useEffect(() => {
    const initialValues = {};
    const componentTokens =
      editedTokens[componentId]?.[viewportSize] ||
      designTokens[componentId]?.[viewportSize] ||
      (selectedComponentInfo.tokens && selectedComponentInfo.tokens[viewportSize]) ||
      {};
    Object.keys(componentTokens).forEach((propertyType) => {
      initialValues[propertyType] = componentTokens[propertyType];
    });
    setLocalValues(initialValues);
  }, [componentId, viewportSize, editedTokens, designTokens, selectedComponentInfo.tokens]);

  const menu = (
    <Menu
      onClick={({ key }) => {
        handleTokenChange('display', key);
        setLocalValues((prev) => ({ ...prev, display: key }));
      }}
    >
      {displayValues.map((value) => (
        <Menu.Item key={value}>{value}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Row>
      <Col span={24}>
        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            type="text"
            value={localValues.display || ''}
            placeholder="display"
            readOnly
          />
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </Col>
    </Row>
  );
};

export default DisplayOptions;
