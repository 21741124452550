// frontend/src/components/TabbedInterface.js
import React, { useState, useEffect } from 'react';
import UiBuilderControls from '../components/uiBuilderControls';
import { CloseOutlined } from '@ant-design/icons';

const TabbedInterface = ({ pages = [], activePage, onSelectPage, setActivePage, handleCloseTab, openTabs, setOpenTabs, onSave, onSavePageContainer, onRepoSelect, onViewportChange, processVoiceCommand }) => {

  useEffect(() => {
    // Update activePage state when pages array changes
    if (pages.length > 0 && (!activePage || !pages.some(page => page.pageId === activePage))) {
      setActivePage(pages[0].pageId);
      onSelectPage(pages[0].pageId); // Call onSelectPage for the first page
    }
  }, [pages, onSelectPage, activePage, setActivePage]);

  const handleTabClick = (pageId) => {
      // Only update if a different tab is clicked
      if (pageId !== activePage) {
          setActivePage(pageId);
          onSelectPage(pageId);
      }
  };

  return (
    <div className="tabbed-interface">
      <ul className="tabs">
        {pages.filter(page => openTabs.includes(page.pageId)).map(page => (
          <li key={page.pageId} className={page.pageId === activePage ? 'active' : ''}>
            <span onClick={() => handleTabClick(page.pageId)}>
              {page.pageName}
            </span>
            <CloseOutlined onClick={(e) => {
              e.stopPropagation();
              handleCloseTab(page.pageId);
            }} />
          </li>
        ))}
      </ul>
      <UiBuilderControls 
        onSave={onSave} 
        onSavePageContainer={onSavePageContainer} 
        onRepoSelect={onRepoSelect} 
        onViewportChange={onViewportChange}
        processVoiceCommand={processVoiceCommand}
      />
      <div className="tab-content">
        {pages.map((page) => (
          <div
            key={page.pageId}
            className={`tab ${page.pageId === activePage ? 'active' : ''}`}
          >
            {page.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabbedInterface;