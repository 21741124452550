// frontend/src/pages/AnalyticsTesting.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import { Button, Input, Select, Table, Modal, Form, List, DatePicker, message } from 'antd';
import HeatmapOverlay from '../components/HeatmapOverlay';
import moment from 'moment';
import FeedbackPopup from '../components/FeedbackPopup';

const { Option } = Select;
const { RangePicker } = DatePicker;

const AnalyticsTesting = ({ isLoggedIn, setIsLoggedIn }) => {
  const [tests, setTests] = useState([]);
  const [pages, setPages] = useState([]);
  const [newTest, setNewTest] = useState({
    originalPageId: '',
    variations: [],
    startDate: null,
    endDate: null,
  });
  const [clickEvents, setClickEvents] = useState([]);
  const [editingTest, setEditingTest] = useState(null);
  const [selectedPageId, setSelectedPageId] = useState('');

  useEffect(() => {
    fetchPages();
    fetchTests();
  }, []);

  const fetchPages = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/pageContent', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPages(response.data);
    } catch (error) {
      console.error('Error fetching pages:', error);
      message.error('Failed to fetch pages.');
    }
  };

  const fetchTests = async () => {
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const response = await axios.get(`/abTest/user/${userId}/tests`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTests(response.data);
    } catch (error) {
      console.error('Error fetching A/B tests:', error);
      message.error('Failed to fetch A/B tests.');
    }
  };

  const handleCreateTest = async () => {
    try {
      const token = localStorage.getItem('token');

      // Validate newTest
      if (!newTest.originalPageId) {
        message.error('Original Page is required.');
        return;
      }
      if (!newTest.variations.length) {
        message.error('At least one variation is required.');
        return;
      }
      if (!newTest.startDate || !newTest.endDate) {
        message.error('Start Date and End Date are required.');
        return;
      }

      const formattedTest = {
        originalPageId: newTest.originalPageId,
        variations: newTest.variations.map((pageId) => ({ pageId, weight: 50 })), // Example: equal weight
        startDate: newTest.startDate.toISOString(),
        endDate: newTest.endDate.toISOString(),
      };

      await axios.post('/abTest/create', formattedTest, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success('A/B Test created successfully.');
      setNewTest({
        originalPageId: '',
        variations: [],
        startDate: null,
        endDate: null,
      });
      fetchTests();
    } catch (error) {
      console.error('Error creating A/B test:', error);
      message.error('Failed to create A/B test.');
    }
  };

  const handleUpdateTest = async (testId) => {
    try {
      const token = localStorage.getItem('token');

      // Validate editingTest
      if (!editingTest.startDate || !editingTest.endDate) {
        message.error('Start Date and End Date are required.');
        return;
      }

      const formattedUpdates = {
        startDate: editingTest.startDate.toISOString(),
        endDate: editingTest.endDate.toISOString(),
        // Optionally, update variations or other fields
      };

      await axios.put(`/abTest/${testId}`, formattedUpdates, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success('A/B Test updated successfully.');
      setEditingTest(null);
      fetchTests();
    } catch (error) {
      console.error('Error updating A/B test:', error);
      message.error('Failed to update A/B test.');
    }
  };

  const handleDeleteTest = async (testId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/abTest/${testId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      message.success('A/B Test deleted successfully.');
      fetchTests();
    } catch (error) {
      console.error('Error deleting A/B test:', error);
      message.error('Failed to delete A/B test.');
    }
  };

  const fetchClickEvents = async (pageId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/analytics/events?eventType=click&pageId=${pageId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Ensure events is an array
      setClickEvents(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching click events:', error);
      message.error('Failed to fetch click events.');
      setClickEvents([]); // Set to empty array on error
    }
  };

  const handlePageSelection = (pageId) => {
    setSelectedPageId(pageId);
    fetchClickEvents(pageId);
  };

  const columns = [
    {
      title: 'Test ID',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'Original Page',
      dataIndex: 'originalPageId',
      key: 'originalPageId',
      render: (pageId) => {
        const page = pages.find((p) => p.pageId === pageId);
        return page ? page.pageName : 'Unknown';
      },
    },
    {
      title: 'Variations',
      dataIndex: 'variations',
      key: 'variations',
      render: (variations) => (
        <List
          dataSource={variations}
          renderItem={(item) => (
            <List.Item>
              <span>{item.pageId}</span>
              <span>Weight: {item.weight}%</span>
            </List.Item>
          )}
        />
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date) => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date) => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => setEditingTest(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDeleteTest(record._id)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <div className="analyticsMainWrapper">
        <h1>A/B Testing Dashboard</h1>
        {/* Form to Create New A/B Test */}
        <div className="analyticsContentWrapper">
          <h2>Create New A/B Test</h2>
          <Form layout="vertical">
            <Form.Item label="Original Page">
              <Select
                value={newTest.originalPageId}
                onChange={(value) => setNewTest({ ...newTest, originalPageId: value })}
                placeholder="Select the original page"
              >
                {pages.map((page) => (
                  <Option key={page.pageId} value={page.pageId}>
                    {page.pageName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Variations (Select Multiple Pages)">
              <Select
                mode="multiple"
                value={newTest.variations}
                onChange={(values) => setNewTest({ ...newTest, variations: values })}
                placeholder="Select variation pages"
              >
                {pages.map((page) => (
                  <Option key={page.pageId} value={page.pageId}>
                    {page.pageName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Test Duration">
              <RangePicker
                value={newTest.startDate && newTest.endDate ? [moment(newTest.startDate), moment(newTest.endDate)] : []}
                onChange={(dates) => {
                  if (dates) {
                    setNewTest({
                      ...newTest,
                      startDate: dates[0].startOf('day').toDate(),
                      endDate: dates[1].endOf('day').toDate(),
                    });
                  } else {
                    setNewTest({
                      ...newTest,
                      startDate: null,
                      endDate: null,
                    });
                  }
                }}
              />
            </Form.Item>

            <Button type="primary" onClick={handleCreateTest}>
              Create Test
            </Button>
          </Form>
        </div>

        {/* Table to Display Existing Tests */}
        <div className="analyticsContentExistingTests">
          <h2>Existing A/B Tests</h2>
          <Table
            dataSource={tests}
            columns={columns}
            rowKey="_id"
            pagination={{ pageSize: 5 }}
          />
        </div>

        {/* Modal to Edit Test */}
        <Modal
          visible={!!editingTest}
          title="Edit A/B Test"
          onCancel={() => setEditingTest(null)}
          onOk={() => handleUpdateTest(editingTest._id)}
          okText="Save"
        >
          {editingTest && (
            <Form layout="vertical">
              <Form.Item label="Start Date">
                <DatePicker
                  value={moment(editingTest.startDate)}
                  onChange={(date) => setEditingTest({ ...editingTest, startDate: date.toDate() })}
                />
              </Form.Item>
              <Form.Item label="End Date">
                <DatePicker
                  value={moment(editingTest.endDate)}
                  onChange={(date) => setEditingTest({ ...editingTest, endDate: date.toDate() })}
                />
              </Form.Item>
              {/* Add more fields as needed, e.g., modifying variations */}
            </Form>
          )}
        </Modal>

        {/* Heatmap Section */}
        <div className="analyticsContentHeatmapSection">
          <h2>Heatmap</h2>
          <Select
            placeholder="Select a Page to View Heatmap"
            onChange={handlePageSelection}
          >
            {pages.map((page) => (
              <Option key={page.pageId} value={page.pageId}>
                {page.pageName}
              </Option>
            ))}
          </Select>

          {selectedPageId && (
            <div className="analyticsHeatmapDivFrame" style={{ position: 'relative', width: '100%', height: '100%' }}>
              <iframe
                src={`/preview/${selectedPageId}`}
                title="Page Preview"
                style={{ width: '100%', height: '100%', pointerEvents: 'none' }}
              />
              <HeatmapOverlay events={clickEvents} />
            </div>
          )}
        </div>
        <FeedbackPopup page="Analytics and Testing" />
      </div>
    </>
  );
};

export default AnalyticsTesting;
