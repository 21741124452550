// frontend/src/components/HeatmapOverlay.js
import React, { useEffect, useRef } from 'react';
import h337 from 'heatmap.js';
import PropTypes from 'prop-types';

const HeatmapOverlay = ({ events }) => {
  const heatmapContainerRef = useRef(null);
  const heatmapInstanceRef = useRef(null);

  useEffect(() => {
    if (heatmapContainerRef.current) {
      if (!heatmapInstanceRef.current) {
        heatmapInstanceRef.current = h337.create({
          container: heatmapContainerRef.current,
          radius: 40,
          maxOpacity: 0.6,
          minOpacity: 0,
          blur: 0.75,
        });
      }

      const points = Array.isArray(events)
        ? events.map(event => ({
            x: event.coordinates.x,
            y: event.coordinates.y,
            value: 1, // Each click counts as 1
          }))
        : [];

      const data = {
        max: 10, // Adjust based on your needs
        data: points,
      };

      heatmapInstanceRef.current.setData(data);
    }
  }, [events]);

  return (
    <div
      ref={heatmapContainerRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none', // Allows clicks to pass through to the iframe
      }}
    />
  );
};

HeatmapOverlay.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      value: PropTypes.number,
    })
  ).isRequired,
};

export default HeatmapOverlay;
