// frontend/src/contexts/TemplateContext.js

import React, { createContext, useState } from 'react';

export const TemplateContext = createContext();

export const TemplateProvider = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const openTemplateModal = (rowData) => {
    console.log('Opening template modal with rowData:', rowData);
    setSelectedRowData(rowData);
    setIsModalVisible(true);
  };

  const closeTemplateModal = () => {
    console.log('closeTemplateModal called');
    setSelectedRowData(null);
    setIsModalVisible(false);
  };

  return (
    <TemplateContext.Provider value={{ 
      isModalVisible, 
      selectedRowData, 
      openTemplateModal, 
      closeTemplateModal 
    }}>
      {children}
    </TemplateContext.Provider>
  );
};
