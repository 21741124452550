// frontend/src/components/FeedbackPopup.js

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, message } from 'antd';
import axios from 'axios';

const { TextArea } = Input;

const FeedbackPopup = ({ page }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  // Constants for timing
  const INITIAL_DELAY = 30 * 60 * 1000; // 30 minutes in milliseconds
  const REPEAT_DELAY = 60 * 60 * 1000; // 1 hour in milliseconds

  useEffect(() => {
    // Check if enough time has passed since the last popup
    const lastPopupTime = localStorage.getItem('lastPopupTime');
    const now = Date.now();

    if (!lastPopupTime || now - lastPopupTime >= INITIAL_DELAY) {
      // Schedule the popup to show after 30 minutes if it's the first time
      const timer = setTimeout(() => {
        setIsPopupVisible(true);
        localStorage.setItem('lastPopupTime', now); // Update the last popup time
      }, INITIAL_DELAY);

      return () => clearTimeout(timer); // Clean up the timer
    } else {
      // Calculate the remaining time until the next popup
      const timeSinceLastPopup = now - lastPopupTime;
      const remainingTime = REPEAT_DELAY - timeSinceLastPopup;

      if (remainingTime > 0) {
        // Schedule the next popup for 1 hour after the last one
        const timer = setTimeout(() => {
          setIsPopupVisible(true);
          localStorage.setItem('lastPopupTime', now);
        }, remainingTime);

        return () => clearTimeout(timer); // Clean up the timer
      }
    }
  }, []);

  const handleFeedbackYes = () => {
    setIsPopupVisible(false);
    setIsModalVisible(true);
  };

  const handleFeedbackLater = () => {
    setIsPopupVisible(false);
    sessionStorage.setItem('feedbackAsked', 'true');
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        '/feedback/submit',
        {
          page,
          feedbackText: values.feedback,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success('Thank you for your feedback!');
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      message.error('Failed to submit feedback. Please try again later.');
    }
  };

  return (
    <>
      {/* Feedback Popup */}
      <Modal
        title="We Value Your Feedback!"
        visible={isPopupVisible}
        onCancel={handleFeedbackLater}
        footer={[
          <Button key="later" onClick={handleFeedbackLater}>
            Later
          </Button>,
          <Button key="yes" type="primary" onClick={handleFeedbackYes}>
            Yes
          </Button>,
        ]}
      >
        <p>Would you like to provide feedback on your session so far?</p>
      </Modal>

      {/* Feedback Form Modal */}
      <Modal
        title="Provide Your Feedback"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <h3>We would Love to Hear Your Thoughts</h3>
          <p>Please share your feedback about your experience on the {page} page.</p>
          <Form.Item
            name="feedback"
            label="Your Feedback"
            rules={[
              { required: true, message: 'Please enter your feedback.' },
              { min: 10, message: 'Feedback must be at least 10 characters.' },
            ]}
          >
            <TextArea rows={4} placeholder="Enter your feedback here..." />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Submit Feedback
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default FeedbackPopup;
