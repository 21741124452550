// frontend/src/components/StorageUsage.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { message } from 'antd';
import {
  FileTextOutlined,   // For Documents
  PictureOutlined,     // For Images
  VideoCameraOutlined, // For Video
  AppstoreOutlined,    // For Icons
  SoundOutlined,       // For Music
} from '@ant-design/icons';

const MAX_STORAGE = 5 * 1024 * 1024 * 1024; // 5GB in bytes

const StorageUsage = () => {
  const [folderSizes, setFolderSizes] = useState({});
  const [totalUsage, setTotalUsage] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStorageUsage = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/media/storage-usage', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setFolderSizes(response.data.folderSizes);
        setTotalUsage(response.data.totalUsage);
      } catch (error) {
        console.error('Error fetching storage usage:', error);
        message.error('Failed to fetch storage usage.');
      } finally {
        setLoading(false);
      }
    };

    fetchStorageUsage();
  }, []);

  if (loading) {
    return <div>Loading storage usage...</div>;
  }

  // Calculate percentages
  const usedGB = (totalUsage / (1024 * 1024 * 1024)).toFixed(2); // Convert to GB
  const totalGB = 5; // 5GB

  const folderPercentages = {};
  for (const [folder, size] of Object.entries(folderSizes)) {
    folderPercentages[folder] = (size / MAX_STORAGE) * 100;
  }

  const folderIcons = {
    Documents: <FileTextOutlined style={{ fontSize: '24px', color: '#4caf50' }} />,
    Images: <PictureOutlined style={{ fontSize: '24px', color: '#2196f3' }} />,
    Video: <VideoCameraOutlined style={{ fontSize: '24px', color: '#f44336' }} />,
    Icons: <AppstoreOutlined style={{ fontSize: '24px', color: '#ff9800' }} />,
    Music: <SoundOutlined style={{ fontSize: '24px', color: '#9c27b0' }} />,
  };

  return (
    <div className="user-dashboard-storage-wrap">
      <h2>Data Storage</h2>
      <div className="data-usage-breakdown-wrap">
        <div className="data-storage-bar-indicator">
          <div className="data-storage-bar-indicator-content">
            <div className="data-storage-bar-indicator-content-gb-usage">
              <h4>{usedGB}</h4>
              <p>GB of</p>
              <h4>{totalGB}</h4>
              <p>GB</p>
            </div>
            <p>Your storage breakdown</p>
          </div>
          <div className="data-storage-bar-indicator-bar">
            <div
              className="data-storage-bar-indicator-bar-documents"
              style={{ width: `${folderPercentages['Documents'] || 0}%` }}
            ></div>
            <div
              className="data-storage-bar-indicator-bar-images"
              style={{ width: `${folderPercentages['Images'] || 0}%` }}
            ></div>
            <div
              className="data-storage-bar-indicator-bar-videos"
              style={{ width: `${folderPercentages['Video'] || 0}%` }}
            ></div>
            <div
              className="data-storage-bar-indicator-bar-icons"
              style={{ width: `${folderPercentages['Icons'] || 0}%` }}
            ></div>
            <div
              className="data-storage-bar-indicator-bar-music"
              style={{ width: `${folderPercentages['Music'] || 0}%` }}
            ></div>
          </div>
        </div>

        <div className="data-storage-breakdown-details">
          {['Documents', 'Images', 'Video', 'Icons', 'Music'].map((folder) => (
            <React.Fragment key={folder}>
              <div className={`data-storage-breakdown-details-${folder.toLowerCase()}`}>
                <div className="data-storage-breakdown-details-label">
                  <div className="data-storage-breakdown-details-label-icon">
                    {folderIcons[folder]}
                  </div>
                  <p>{folder}</p>
                </div>
                <p>{((folderSizes[folder] || 0) / (1024 * 1024)).toFixed(2)} MB</p>
              </div>
              <div className="data-storage-breakdown-details-seperator"></div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StorageUsage;
