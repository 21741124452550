// frontend/src/actions/addActions.js

export const handleAddActions = (actionDetails, executeAction) => {
  const { componentName, rowIndex, columnIndex } = actionDetails;

  if (!componentName) {
    alert('Component name is required to add.');
    return;
  }

  executeAction({
  	type: 'add',
    componentName,
    rowIndex,
    columnIndex,
  });
};
