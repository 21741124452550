// frontend/src/components/SelectionPanel.js
import React, { useContext, useState, useEffect } from 'react';
import { ComponentPropsContext } from '../contexts/ComponentPropsContext';
import Draggable from 'react-draggable'; 
import { Switch, Collapse, Select, Spin, Input, Button } from 'antd';
import axios from 'axios';
import AddNestedComponent from './ui_components/AddNestedComponent';
import { SelectedComponentContext } from '../contexts/SelectedComponentContext';
import { TokensContext } from '../contexts/TokensContext';
import { propCategories } from '../utils/propCategories';
import * as AntdIcons from '@ant-design/icons';

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

const SelectionPanel = ({ onClose, pages, onRemoveComponent }) => {
  const { designTokens, editedTokens, tokens } = useContext(TokensContext);
  const { selectedComponentInfo } = useContext(SelectedComponentContext);
  const { updateComponentProps } = useContext(ComponentPropsContext);
  const [activeTab, setActiveTab] = useState('Visual Editor');
  const [localProps, setLocalProps] = useState(selectedComponentInfo?.props || {});
  const [colorValue, setColorValue] = useState(null);
  const [tokenNames, setTokenNames] = useState({});
  // images items
  const [loading, setLoading] = useState(false);
  const [mediaOptions, setMediaOptions] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState(selectedComponentInfo?.props?.backgroundImage || '');
  const [fillOption, setFillOption] = useState(localProps.fill || 'cover');
  // Type items
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [textValue, setTextValue] = useState(selectedComponentInfo?.props?.content || '');
  // seo props
  const seoProps = ['seoTitle', 'seoDescription', 'altText', 'ariaLabel'];
  // icon props
  const [iconOptions, setIconOptions] = useState([]);
  const [activeIconTab, setActiveIconTab] = useState('outline');
  // Categorize icons into outline, filled, and two-tone
  const iconCategories = {
    outline: Object.entries(AntdIcons).filter(([key]) => key.endsWith('Outlined')),
    filled: Object.entries(AntdIcons).filter(([key]) => key.endsWith('Filled')),
    twoTone: Object.entries(AntdIcons).filter(([key]) => key.endsWith('TwoTone')),
  };

  useEffect(() => {
    console.log('Selected component props:', selectedComponentInfo?.props);
    setLocalProps(selectedComponentInfo?.props || {});
  }, [selectedComponentInfo]);

  useEffect(() => {
    console.log('SelectionPanel - Selected Component Info:', selectedComponentInfo);
    const defaultProps = selectedComponentInfo?.component?.defaultProps || {};
    const mergedProps = { ...defaultProps, ...selectedComponentInfo?.props };
    console.log('Merged props:', mergedProps);
    setLocalProps(mergedProps || {});
    setBackgroundImage(mergedProps.backgroundImage || '');
    setFillOption(mergedProps.fill || 'cover');
    if ('src' in mergedProps) {
      fetchImages();
    }
  }, [selectedComponentInfo]);

  useEffect(() => {
    if (selectedComponentInfo?.name === 'Container') {
      fetchImages(); // Fetch images if Container is selected
    }
  }, [selectedComponentInfo]);

  // Function to fetch images from S3
  const fetchImages = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get('/media/list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { fileType: 'Images' },
      });
      const images = response.data.files.map(file => ({
        key: file.key,
        name: file.key.split('/').pop(),
        url: `https://webbifymedia.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${file.key}`,
      }));
      setMediaOptions(images);
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchIcons = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get('/media/list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { fileType: 'Icons' },
      });
      const icons = response.data.files.map(file => ({
        key: file.key,
        name: file.key.split('/').pop(),
        url: `https://webbifymedia.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${file.key}`,
      }));
      setIconOptions(icons);
    } catch (error) {
      console.error('Error fetching icons:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(localProps).includes('IconSrc')) {
      fetchIcons();
    }
  }, [selectedComponentInfo]);

  const handleImageChange = (value) => {
    const selectedImage = mediaOptions.find(img => img.key === value);
    if (selectedImage) {
      handlePropChange('src', selectedImage.url);
      updateComponentProps(selectedComponentInfo?.id, { src: selectedImage.url }); // Ensure props sync
    }
  };

  const handleBackgroundImageChange = (value) => {
    const selectedImage = mediaOptions.find(img => img.key === value);
    if (selectedImage) {
      updateComponentProps(selectedComponentInfo?.id, { backgroundImage: selectedImage.url });
      setBackgroundImage(selectedImage.url); // Update local state
    }
  };

  const handlePropChange = (propName, newValue) => {
    const updatedValue =
      typeof localProps[propName] === 'boolean' ? newValue : newValue || '';
    updateComponentProps(selectedComponentInfo.id, { [propName]: updatedValue });
    setLocalProps((prevProps) => ({
      ...prevProps,
      [propName]: updatedValue,
    }));
  };

  const handleFillChange = (value) => {
    setFillOption(value);
    handlePropChange('fill', value); // Update component props
    updateComponentProps(selectedComponentInfo?.id, { fill: value }); // Sync with context
  };

  const handleInputBlur = (propName, value) => {
    handlePropChange(propName, value);
  };

  // Text component items
  useEffect(() => {
    setTextValue(selectedComponentInfo?.props?.content || '');
  }, [selectedComponentInfo]);

  const handleContentChange = (newValue) => {
    setTextValue(newValue);
    updateComponentProps(selectedComponentInfo.id, { content: newValue });
  };

  const handleFormattingChange = (formatType) => {
    let updatedContent = localProps.content;
    if (formatType === 'bold') setIsBold(!isBold);
    if (formatType === 'italic') setIsItalic(!isItalic);
    // Update styling based on toggles
    updateComponentProps(selectedComponentInfo.id, {
      tokens: {
        ...localProps.tokens,
        fontWeight: isBold ? 'bold' : 'normal',
        fontStyle: isItalic ? 'italic' : 'normal',
      },
    });
  };

  // Render SEO inputs 
  const renderSeoInputs = () => {
    return seoProps.map((prop) => (
      <div key={prop} className="seo-input">
        <label>{prop.replace(/([A-Z])/g, ' $1')}</label>
        <Input
          value={localProps[prop] || ''} // Use `localProps` to handle the current value
          onChange={(e) => setLocalProps((prev) => ({ ...prev, [prop]: e.target.value }))}
          onBlur={(e) => handleInputBlur(prop, e.target.value)} // Save changes on blur
          placeholder={`Enter ${prop.replace(/([A-Z])/g, ' $1').toLowerCase()}`}
        />
      </div>
    ));
  }; // end of render SEO inputs

  const handleMediaSelect = (propName, value) => {
    const selectedImage = mediaOptions.find(img => img.url === value);
    if (selectedImage) {
      handlePropChange(propName, selectedImage.url);
    }
  };


  const renderIconSelector = (propName) => (
    <div key={propName} className="icon-selector">
      <label>{propName}</label>
      <Select
        value={localProps[propName] || ''}
        onChange={(value) => handlePropChange(propName, value)}
        placeholder={`Select an icon for ${propName}`}
        dropdownRender={(menu) => (
          <div>
            <div className="icon-tabs">
              {Object.keys(iconCategories).map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveIconTab(tab)}
                  className={activeIconTab === tab ? 'active' : ''}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
            {menu}
          </div>
        )}
        style={{ width: '100%' }}
        showSearch
        filterOption={(input, option) =>
          option.key.toLowerCase().includes(input.toLowerCase())
        }
      >
        {iconCategories[activeIconTab].map(([name, Icon]) => (
          <Option key={name} value={name}>
            <Icon style={{ marginRight: '10px' }} />
            {name}
          </Option>
        ))}
      </Select>
    </div>
  ); // end of renderIconSlector

  const renderProp = (prop) => {
    const { key, type, placeholder, options, switch: hasSwitch } = prop;

    // If the selected component does not have this prop, do not render it
    if (!Object.keys(localProps).includes(key)) {
      return null;
    }

    const commonProps = {
      value: localProps[key] || '',
      onChange: (e) => handlePropChange(key, e.target.value),
      placeholder,
    };

    switch (type) {
      case 'icon':
        return renderIconSelector(key);
      case 'switch':
        return (
          <div key={key}>
            <label>{key}</label>
            <Switch
              checked={localProps[key] || false}
              onChange={(checked) => handlePropChange(key, checked)}
            />
          </div>
        );
      case 'input':
        return (
          <div key={key}>
            <label>{key}</label>
            {hasSwitch && (
              <Switch
                checked={!!localProps[key]}
                onChange={(checked) => handlePropChange(key, checked ? '' : undefined)}
              />
            )}
            <Input {...commonProps} />
          </div>
        );
      case 'textarea':
        return (
          <div key={key}>
            <label>{key}</label>
            <TextArea {...commonProps} rows={4} />
          </div>
        );
      case 'select':
        return (
          <div key={key}>
            <label>{key}</label>
            <Select
              value={localProps[key] || ''}
              onChange={(value) => {
                if (options === 'media') {
                  handleMediaSelect(key, value);
                } else if (options === 'pages') {
                  handlePropChange(key, value);
                } else {
                  handlePropChange(key, value);
                }
              }}
              placeholder={placeholder}
              style={{ width: '100%' }}
            >
              {options === 'media'
                ? mediaOptions.map(image => (
                    <Option key={image.key} value={image.url}>
                      {image.name}
                    </Option>
                  ))
                : options === 'pages'
                ? pages.map(page => (
                    <Option key={page.pageId} value={`/${page.pageName}`}>
                      {page.pageName}
                    </Option>
                  ))
                : options.map(option => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
            </Select>
          </div>
        );
      default:
        return null;
    }
  }; // end of renderProp

  const renderTabContent = (category) => {
    const defaultProps = selectedComponentInfo?.component?.defaultProps || {};
    const allProps = { ...defaultProps, ...selectedComponentInfo?.props };
    return propCategories[category]
      ?.filter((prop) => prop.key in allProps)
      .map((prop) => renderProp(prop));
  };

  // action to remve component
  const handleRemoveClick = () => {
    if (selectedComponentInfo?.id) {
      if (window.confirm('Are you sure you want to remove this component?')) {
        onRemoveComponent(selectedComponentInfo.id);
      }
    }
  };

  return (
    <Draggable bounds="parent">
      <div className="selection-panel">
        <header>
          <h4>Selected component: {selectedComponentInfo?.name}</h4>
          <button onClick={onClose}>Close</button>
        </header>
        <div className="tabs">
          <button
            onClick={() => setActiveTab('Visual Editor')}
            className={activeTab === 'Visual Editor' ? 'active' : ''}
          >
            Visual Editor
          </button>
          <button onClick={() => setActiveTab('SEO')} className={activeTab === 'SEO' ? 'active' : ''}>
            SEO
          </button>
          <button
            onClick={() => setActiveTab('Interaction')}
            className={activeTab === 'Interaction' ? 'active' : ''}
          >
            Interaction
          </button>
          <button onClick={() => setActiveTab('Motion')} className={activeTab === 'Motion' ? 'active' : ''}>
            Motion
          </button>
        </div>
        {activeTab === 'Visual Editor' && (
          <div className="visual-editor">
            {selectedComponentInfo?.name === 'Container' && (
              <>
                <AddNestedComponent
                  containerId={selectedComponentInfo.id}
                  tokens={localProps.tokens || {}}
                  designTokens={designTokens}
                  editedTokens={editedTokens}
                />
              </>
            )}
            <div className="remove-button-container">
              <Button
                type="danger"
                icon={<AntdIcons.CloseOutlined />}
                onClick={handleRemoveClick}
                style={{ color: '#ffffff', border: '1px solid #ffffff' }}
              >
                Remove Component
              </Button>
            </div>
            {renderTabContent('content')}
            {renderTabContent('icon')}
            {renderTabContent('image')}
          </div>
        )}
        {activeTab === 'SEO' && (
          <div className="seo-tab">
            {renderTabContent('seo')}
          </div>
        )}
        {activeTab === 'Interaction' && (
          <div className="interaction-tab">
            {renderTabContent('interaction')}
          </div>
        )}
        {activeTab === 'Motion' && (
          <div className="motion-tab">
            {renderTabContent('motion')}
          </div>
        )}
      </div>
    </Draggable>
  );
};

export default SelectionPanel;