// frontend/src/utils/actionMappings.js

import { handleSaveActions } from '../actions/saveActions';
import { handleCreateActions } from '../actions/createActions';
import { handleAddActions } from '../actions/addActions';
import { handleSelectActions } from '../actions/selectActions';

export const actionMappings = [
  {
    type: 'save',
    keywords: [
      'save the page',
      'save changes',
      'save'
    ],
    handler: handleSaveActions,
  },
  {
    type: 'create',
    keywords: [
      'create a new row',
      'create row',
      'add a new row',
      'add row'
    ],
    handler: handleCreateActions,
  },
  {
    type: 'add',
    keywords: [
      'add', 
      'insert',
      'place',
      'drop',
      'add a',
      'insert a',
      'place a',
      'drop a'
    ],
    handler: handleAddActions,
  },
  {
    type: 'select',
    keywords: [
      'select',
      'choose',
      'highlight',
      'select a',
      'select the',
      'choose the',
      'highlight the'
    ],
    handler: handleSelectActions,
  },
];
