// src/utils/voiceRecognition.js

import { generateUniquePageId } from './pageUtils';
import { actionMappings } from './actionMappings';
import { components } from '../components/ComponentImports';
import { loadComponentFromS3, registerDynamicComponent } from '../components/DynamicComponentLoader';

export const startVoiceRecognition = (processVoiceCommand, setIsListening) => {
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

  if (!SpeechRecognition) {
    alert('Your browser does not support speech recognition.');
    return;
  }

  const recognition = new SpeechRecognition();
  recognition.lang = 'en-US';
  recognition.interimResults = false;
  recognition.maxAlternatives = 1;

  recognition.start();
  setIsListening(true);

  recognition.onresult = (event) => {
    const speechResult = event.results[0][0].transcript.toLowerCase();
    console.log('Speech received: ', speechResult);
    setIsListening(false);
    processVoiceCommand(speechResult);
  };

  recognition.onspeechend = () => {
    recognition.stop();
    setIsListening(false);
  };

  recognition.onerror = (event) => {
    console.error('Speech recognition error detected: ', event.error);
    alert('Error during speech recognition: ' + event.error);
    setIsListening(false);
  };
};

export const processVoiceCommand = (command, executeAction) => {
  const lowerCommand = command.toLowerCase();

  for (const mapping of actionMappings) {
    if (mapping.keywords.some((keyword) => lowerCommand.includes(keyword))) {
      const actionDetails = parseActionDetails(lowerCommand, mapping.type);
      if (actionDetails) {
        mapping.handler(actionDetails, executeAction);
        return;
      }
    }
  }

  alert('Sorry, I did not understand the command.');
};

const parseActionDetails = (command, actionType) => {
  const details = { type: actionType };

  switch (actionType) {
    case 'save':
      // No additional details needed
      break;

    case 'create':
      const createMatch = command.match(/create\s+(?:a\s+)?new\s+row(?:\s+with\s+(\d+)\s+columns?)?/i);
      if (createMatch) {
        details.columnCount = parseInt(createMatch[1], 10) || 1;
      }
      break;

    case 'add':
      const addMatch = command.match(
        /add\s+(?:a\s+|the\s+)?(.+?)(?=\s+(?:component|in\s+row|column|$))(?:\s+component)?(?:\s+in\s+row\s+(\w+))?(?:\s+column\s+(\w+))?/i
      );
      if (addMatch) {
        details.componentName = normalizeComponentName(addMatch[1]);
        if (addMatch[2]) {
          details.rowIndex = parseOrdinal(addMatch[2]);
        }
        if (addMatch[3]) {
          details.columnIndex = parseOrdinal(addMatch[3]);
        }
      }
      break;

    case 'select':
      const selectMatch = command.match(
        /select\s+(?:a\s+|the\s+)?(.+?)(?=\s+(?:component|in\s+row|column|$))(?:\s+component)?(?:\s+in\s+row\s+(\w+))?(?:\s+column\s+(\w+))?/i
      );
      if (selectMatch) {
        details.componentName = normalizeComponentName(selectMatch[1]);
        if (selectMatch[2]) {
          details.rowIndex = parseOrdinal(selectMatch[2]);
        }
        if (selectMatch[3]) {
          details.columnIndex = parseOrdinal(selectMatch[3]);
        }
      }
      break;

    default:
      break;
  }

  return details;
};

const parseOrdinal = (word) => {
  const ordinals = {
    first: 0,
    second: 1,
    third: 2,
    fourth: 3,
    fifth: 4,
    sixth: 5,
    seventh: 6,
    eighth: 7,
    ninth: 8,
    tenth: 9,
    eleventh: 10,
    twelfth: 11,
  };

  word = word.toLowerCase();

  if (ordinals[word] !== undefined) {
    return ordinals[word];
  }

  // Handle numeric ordinals like "1st", "2nd", etc.
  const numericMatch = word.match(/^(\d+)(st|nd|rd|th)?$/);
  if (numericMatch) {
    return parseInt(numericMatch[1], 10) - 1;
  }

  // Handle numeric words like "one", "two", etc.
  const numbers = {
    one: 0,
    two: 1,
    three: 2,
    four: 3,
    five: 4,
    six: 5,
    seven: 6,
    eight: 7,
    nine: 8,
    ten: 9,
    eleven: 10,
    twelve: 11,
  };

  if (numbers[word] !== undefined) {
    return numbers[word];
  }

  return undefined;
};

const normalizeComponentName = (name) => {
  if (!name) return null;

  console.log('Raw input:', name);

  const formattedName = name
    .replace(/\bcomponent\b/i, '')
    .trim()
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');

  console.log('Normalized name:', formattedName);

  if (components[formattedName]) {
    return formattedName;
  }

  console.warn(`Component '${formattedName}' not found.`);
  return null;
};
