// frontend/src/components/properties-sidebar/FloatOptions.js
import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Dropdown, Button, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getRelevantTokens } from './utils';

const FloatOptions = ({
  selectedComponentInfo,
  globalTokens,
  editedTokens,
  designTokens,
  tokenNames,
  handleTokenChange,
  setEditedTokens,
  setTokenNames,
  viewportSize,
}) => {
  if (!selectedComponentInfo || !selectedComponentInfo.id) return null;

  const componentId = selectedComponentInfo.id;

  // State for local values
  const [localValues, setLocalValues] = useState({});

  useEffect(() => {
    const initialValues = {};
    const componentTokens =
      editedTokens[componentId]?.[viewportSize] ||
      designTokens[componentId]?.[viewportSize] ||
      (selectedComponentInfo.tokens && selectedComponentInfo.tokens[viewportSize]) ||
      {};

    Object.keys(componentTokens).forEach((propertyType) => {
      initialValues[propertyType] = componentTokens[propertyType];
    });

    setLocalValues(initialValues);
  }, [componentId, viewportSize, editedTokens, designTokens, selectedComponentInfo.tokens]);

  const labelInputDropdown = (label, propertyType) => {
    const inputValue =
      localValues[propertyType] ||
      editedTokens[componentId]?.[viewportSize]?.[propertyType] ||
      designTokens[componentId]?.[viewportSize]?.[propertyType] ||
      selectedComponentInfo.tokens?.[propertyType] ||
      '';

    const tokenName = tokenNames[componentId]?.[propertyType] || '';
    const relevantTokens = getRelevantTokens(globalTokens, propertyType);

    const menu = (
      <Menu
        onClick={(e) => {
          const tokenValue = relevantTokens[e.key];
          handleTokenChange(propertyType, tokenValue);
          setEditedTokens((prevTokens) => ({
            ...prevTokens,
            [componentId]: {
              ...(prevTokens[componentId] || {}),
              [viewportSize]: {
                ...(prevTokens[componentId]?.[viewportSize] || {}),
                [propertyType]: tokenValue,
              },
            },
          }));
          setTokenNames((prevTokenNames) => ({
            ...prevTokenNames,
            [componentId]: {
              ...prevTokenNames[componentId],
              [propertyType]: e.key,
            },
          }));
        }}
      >
        {Object.entries(relevantTokens).map(([key, value]) => (
          <Menu.Item key={key}>{`${key} - ${value}`}</Menu.Item>
        ))}
      </Menu>
    );

    const handleBlurOrEnter = (event) => {
      if (event.type === 'blur' || event.key === 'Enter') {
        const newValue = localValues[propertyType] || inputValue;
        handleTokenChange(propertyType, newValue);
      }
    };

    return (
      <div className="prop-sidebar-item" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <Input
          type="text"
          value={localValues[propertyType] || tokenName || inputValue}
          placeholder={propertyType}
          onChange={(e) => {
            const newValue = e.target.value;
            setLocalValues((prev) => ({
              ...prev,
              [propertyType]: newValue,
            }));
          }}
          onBlur={handleBlurOrEnter}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleBlurOrEnter(e);
          }}
        />
        {Object.keys(relevantTokens).length > 0 && (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button style={{ cursor: 'pointer' }}>
              <DownOutlined />
            </Button>
          </Dropdown>
        )}
      </div>
    );
  };

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={12}>{labelInputDropdown('Float', 'float')}</Col>
        <Col span={12}>{labelInputDropdown('Clear', 'clear')}</Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={12}>{labelInputDropdown('Z-Index', 'zIndex')}</Col>
        <Col span={12}>{labelInputDropdown('Overflow', 'overflow')}</Col>
      </Row>
    </>
  );
};

export default FloatOptions;
