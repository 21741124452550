// frontend/src/components/ComponentImports.js
export const components = { 
  Banner: () => import('./ui_components/Banner/Banner'),
  Card: () => import('./ui_components/Card/Card'),
  Carousel: () => import('./ui_components/Carousel/Carousel'),
  Checkbox: () => import('./ui_components/Checkbox/Checkbox'),
  Container: () => import('./ui_components/Container/Container'),
  Divider: () => import('./ui_components/Divider/Divider'),
  IconButton: () => import('./ui_components/Button/IconButton'),
  Image: () => import('./ui_components/Image/Image'),
  Link: () => import('./ui_components/Link/Link'),
  List: () => import('./ui_components/List/List'),
  MainHero: () => import('./ui_components/MainHero/MainHero'),
  NotificationBanner: () => import('./ui_components/NotificationBanner/NotificationBanner'),
  PrimaryButton: () => import('./ui_components/Button/PrimaryButton'),
  RadioButton: () => import('./ui_components/RadioButton/RadioButton'),
  SecondaryButton: () => import('./ui_components/Button/SecondaryButton'),
  TertiaryButton: () => import('./ui_components/Button/TertiaryButton'),
  Text: () => import('./ui_components/Text/Text'),
  TextArea: () => import('./ui_components/TextArea/TextArea'),
  TextInput: () => import('./ui_components/TextInput/TextInput'),
};
