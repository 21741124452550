// frontend/App.js
import React, { useState, useEffect } from 'react';
//import ReactDOM from 'react-dom';
import { Route, Navigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import axios from 'axios';
import LandingPage from './pages/LandingPage';
import UserDashboard from './pages/UserDashboard';
import CodeEditor from './pages/CodeEditor';
import UiBuilder from './pages/UiBuilder';
import MediaPage from './pages/MediaPage';
import AnalyticsTesting from './pages/AnalyticsTesting';
import './App.css'; 
import { BuilderContext } from './contexts/BuilderContext';
import { TokensProvider } from './contexts/TokensContext';
import { ComponentPropsProvider } from './contexts/ComponentPropsContext';
import { NestedComponentsProvider} from './contexts/NestedComponentsContext';
import { PageContentProvider } from './contexts/PageContentContext';
import { SelectedComponentProvider } from './contexts/SelectedComponentContext';
import MembershipModal from './components/MembershipModal';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [loading, setLoading] = useState(true);
  const [membershipExpired, setMembershipExpired] = useState(false);
  const [userData, setUserData] = useState(null);

  const checkAuthAndMembership = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = tokenPayload.exp * 1000;
      const currentTime = Date.now();
      const timeLeft = expirationTime - currentTime;

      if (timeLeft > 0) {
        // Fetch user data
        try {
          const userResponse = await axios.get('/auth/user', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUserData(userResponse.data);

          // Check membership expiration
          const membershipExpiration = new Date(userResponse.data.membershipExpiration);
          console.log('Membership expiration date:', membershipExpiration);
          console.log('Current date:', new Date());

          if (new Date() > membershipExpiration) {
            setMembershipExpired(true);
            console.log('Membership has expired');
          } else {
            setMembershipExpired(false);
            console.log('Membership is still valid');
          }
          setIsLoggedIn(true);
        } catch (error) {
          console.error('Error fetching user data:', error);

          if (
            error.response &&
            error.response.status === 403 &&
            error.response.data.message === 'Your membership has expired.'
          ) {
            // Membership expired
            setIsLoggedIn(true); // User is authenticated
            setMembershipExpired(true);
          } else {
            // Other errors, assume user is not logged in
            setIsLoggedIn(false);
            //localStorage.removeItem('token');
            //localStorage.removeItem('userId');
          }
        }

        // Set a timer to log out when the token expires
        const logoutTimer = setTimeout(() => {
          setIsLoggedIn(false);
          //localStorage.removeItem('token');
          //localStorage.removeItem('userId');
          alert('Your session has expired. Please log in again.');
        }, timeLeft);

        return () => clearTimeout(logoutTimer);
      } else {
        setIsLoggedIn(false);
        //localStorage.removeItem('token');
        //localStorage.removeItem('userId');
      }
    } else {
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkAuthAndMembership();
  }, []);

  if (isLoggedIn === null) {
    return <div>Loading...</div>; // Optionally show a loading spinner
  }
  
  return (
    <BuilderContext.Provider value={true}>
      <TokensProvider>
        <ComponentPropsProvider>
          <NestedComponentsProvider>
            <PageContentProvider>
              <SelectedComponentProvider>
                {/* Only show the MembershipModal if the user is logged in and their membership is expired */}
                {isLoggedIn && membershipExpired && (
                  <MembershipModal
                    setMembershipExpired={setMembershipExpired}
                    refreshUserData={checkAuthAndMembership}
                  />
                )}
                <Router>
                  <Routes>
                    <Route path="/" element={<LandingPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
                    <Route
                      path="/dashboard"
                      element={
                        isLoggedIn ? (
                          !membershipExpired ? (
                            <UserDashboard isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <Navigate to="/" />
                        )
                      }
                    />
                    <Route
                      path="/CodeEditor"
                      element={
                        isLoggedIn ? (
                          !membershipExpired ? (
                            <CodeEditor isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <Navigate to="/" />
                        )
                      }
                    />
                    <Route
                      path="/uibuilder"
                      element={
                        isLoggedIn ? (
                          !membershipExpired ? (
                            <UiBuilder isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <Navigate to="/" />
                        )
                      }
                    />
                    <Route
                      path="/media"
                      element={
                        isLoggedIn ? (
                          !membershipExpired ? (
                            <MediaPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <Navigate to="/" />
                        )
                      }
                    />
                    <Route
                      path="/analytics-testing"
                      element={
                        isLoggedIn ? (
                          !membershipExpired ? (
                            <AnalyticsTesting isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <Navigate to="/" />
                        )
                      }
                    />
                  </Routes>
                </Router>
              </SelectedComponentProvider>
            </PageContentProvider>
          </NestedComponentsProvider>
        </ComponentPropsProvider>
      </TokensProvider>
    </BuilderContext.Provider>
  );
}

// Render the App component inside the 'root' div
//ReactDOM.render(<App />, document.getElementById('root'));

export default App;