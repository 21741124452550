// frontend/src/utils/rowColumnManager.js

import { generateUniquePageId } from './pageUtils';

export const addRow = (rows, setRows) => {
  setRows([
    ...rows,
    {
      columns: [1], // Default to 1 column
      components: [[]], // Default to an empty array of components
    },
  ]);
};

export const removeRow = (rows, setRows, index) => {
  const updatedRows = [...rows];
  updatedRows.splice(index, 1);
  setRows(updatedRows);
};

export const removeColumn = (rows, setRows, rowIndex, colIndex) => {
  const updatedRows = [...rows];
  updatedRows[rowIndex].columns.splice(colIndex, 1);
  updatedRows[rowIndex].components.splice(colIndex, 1);
  setRows(updatedRows);
};

export const addComponentToColumn = (rows, setRows, rowIndex, colIndex, newComponent) => {
  const updatedRows = [...rows];
  if (!Array.isArray(updatedRows[rowIndex].components)) {
    updatedRows[rowIndex].components = [];
  }
  if (!Array.isArray(updatedRows[rowIndex].components[colIndex])) {
    updatedRows[rowIndex].components[colIndex] = [];
  }
  updatedRows[rowIndex].components[colIndex].push(newComponent);
  setRows(updatedRows);
};

export const handleColumnChange = (rows, setRows, rowIndex, newColumnCount) => {
  const updatedRows = [...rows];
  updatedRows[rowIndex].columns = new Array(newColumnCount).fill(1);
  setRows(updatedRows);
};

export const handleColumnDivChange = (rows, setRows, rowIndex, colIndex, newColumnCount) => {
  const updatedRows = [...rows];
  updatedRows[rowIndex].columns[colIndex] = newColumnCount;
  setRows(updatedRows);
};

export const moveRowUp = (rows, setRows, index) => {
  if (index <= 0) return; // Prevent moving the first row up
  const updatedRows = [...rows];
  [updatedRows[index - 1], updatedRows[index]] = [updatedRows[index], updatedRows[index - 1]];
  setRows(updatedRows);
};

export const moveRowDown = (rows, setRows, index) => {
  if (index >= rows.length - 1) return; // Prevent moving the last row down
  const updatedRows = [...rows];
  [updatedRows[index], updatedRows[index + 1]] = [updatedRows[index + 1], updatedRows[index]];
  setRows(updatedRows);
};

export const duplicateRow = (rows, setRows, index, nestedComponents, setNestedComponents) => {
  const updatedRows = [...rows];
  const rowToDuplicate = { ...rows[index] };

  // Duplicate components in each column
  rowToDuplicate.components = rowToDuplicate.components.map((columnComponents) =>
    columnComponents.map((component) => {
      const newComponentId = generateUniquePageId();

      // Handle duplication of nested components if the component is a Container
      if (component.name === 'Container' && nestedComponents[component.id]) {
        const duplicatedNestedComponents = {};

        // Duplicate nested components
        Object.keys(nestedComponents[component.id]).forEach((columnId) => {
          duplicatedNestedComponents[columnId] = nestedComponents[component.id][columnId].map(
            (nestedComponent) => ({
              ...nestedComponent,
              id: generateUniquePageId(), // Ensure unique ID for each nested component
            })
          );
        });

        // Add duplicated nested components to the context
        setNestedComponents((prevNestedComponents) => ({
          ...prevNestedComponents,
          [newComponentId]: duplicatedNestedComponents,
        }));
      }

      return {
        ...component,
        id: newComponentId, // Ensure the duplicated component has a unique ID
      };
    })
  );

  // Insert the duplicated row beneath the original
  updatedRows.splice(index + 1, 0, rowToDuplicate);
  setRows(updatedRows);
};
