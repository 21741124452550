// src/utils/propCategories.js
export const propCategories = {
  seo: [
    { key: 'seoTitle', type: 'input', placeholder: 'Enter SEO Title', switch: true },
    { key: 'seoDescription', type: 'input', placeholder: 'Enter SEO Description', switch: true },
    { key: 'ariaLabel', type: 'input', placeholder: 'Enter ARIA Label', switch: true },
  ],
  content: [
    { key: 'label', type: 'input', placeholder: 'Enter Label', switch: true },
    { key: 'title', type: 'input', placeholder: 'Enter Title' },
    { key: 'content', type: 'textarea', placeholder: 'Enter Content' },
    { key: 'message', type: 'textarea', placeholder: 'Enter Message' },
  ],
  image: [
    { key: 'src', type: 'select', options: 'media', placeholder: 'Select Image Source' },
    { key: 'alt', type: 'input', placeholder: 'Enter Alt Text' },
    { key: 'fill', type: 'select', options: ['cover', 'contain', 'fill', 'scale-down', 'none'], placeholder: 'Select Image Fill' },
    { key: 'backgroundImage', type: 'select', options: 'media', placeholder: 'Select Background Image' },
  ],
  motion: [
    { key: 'autoPlay', type: 'switch', default: false },
    { key: 'interval', type: 'input', placeholder: '3000 (ms)', default: 3000 },
    { key: 'duration', type: 'input', placeholder: '5000 (ms)', default: 5000 },
  ],
  interaction: [
    { key: 'urlLink', type: 'input', placeholder: 'Enter URL Link' },
    { key: 'pageLink', type: 'select', options: 'pages', placeholder: 'Select Page Link' },
    { key: 'newTab', type: 'switch', placeholder: 'Open in New Tab' },
  ],
  icon: [
    { key: 'IconLeft', type: 'switch', placeholder: 'Enable Icon Left' },
    { key: 'IconLeftName', type: 'icon', placeholder: 'Select Icon Left' },
    { key: 'IconRight', type: 'switch', placeholder: 'Enable Icon Right' },
    { key: 'IconRightName', type: 'icon', placeholder: 'Select Icon Right' },
  ],
  select: [
    { key: 'type', type: 'select', options: ['info', 'success', 'warning', 'error'], placeholder: 'Select Type' },
    { key: 'options', type: 'static-select', options: [
      { value: '', label: 'Select an option' },
      { value: 'option1', label: 'Option 1' },
      { value: 'option2', label: 'Option 2' },
      { value: 'option3', label: 'Option 3' },
    ] },
    { key: 'value', type: 'input', placeholder: 'Enter Value' },
  ],
};
