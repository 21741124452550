// frontend/src/pages/UserDashboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Layout, Menu, Input, Button, Select, Modal, Tree } from 'antd';
import Header from '../components/Header';
import UserURLSettings from '../components/UserURLSettings';
import StorageUsage from '../components/StorageUsage';
import { membershipOptions } from '../components/membershipOptions';
import '../UserDashboard.css';
const { Sider, Content } = Layout;
const { Option } = Select;

const UserDashboard = ({ isLoggedIn, setIsLoggedIn }) => {
  const [selectedKey, setSelectedKey] = useState('1');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showReset, setShowReset] = useState(false);
  const [isConnectedToGitHub, setIsConnectedToGitHub] = useState(false);
  const [githubUsername, setGitHubUsername] = useState('');
  const [githubToken, setGitHubToken] = useState('');
  const [githubError, setGitHubError] = useState('');
  const [repos, setRepos] = useState([]);
  const [selectedRepo, setSelectedRepo] = useState('');
  const [branch, setBranch] = useState('main');
  const [userId, setUserId] = useState('');

  const [clonedRepos, setClonedRepos] = useState([]);
  const [selectedClonedRepo, setSelectedClonedRepo] = useState('');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [treeData, setTreeData] = useState([]);

  // New state for Jira Integration
  const [jiraUsername, setJiraUsername] = useState('');
  const [jiraApiToken, setJiraApiToken] = useState('');
  const [jiraDomain, setJiraDomain] = useState('');

  // Membership 
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [membershipType, setMembershipType] = useState('trial');
  const [membershipExpiration, setMembershipExpiration] = useState(null);
  const [showMembershipForm, setShowMembershipForm] = useState(false);

  // Add state variables
  const [customDomain, setCustomDomain] = useState('');
  const [dnsTarget, setDnsTarget] = useState('');
  const [instructions, setInstructions] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        console.log('Token retrieved from localStorage:', token);
        if (!token) {
          console.log('No token available.');
          setIsLoggedIn(false);
          return;
        }
        const userResponse = await axios.get('/auth/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('User data fetched:', userResponse.data);
        setEmail(userResponse.data.email);
        setPassword(userResponse.data.password);
        setUserId(userResponse.data._id);
        setMembershipType(userResponse.data.membershipType);
        setMembershipExpiration(userResponse.data.membershipExpiration);

        // Fetch GitHub details
        const githubResponse = await axios.get('/github/details', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('GitHub details fetched:', githubResponse.data);
        setGitHubUsername(githubResponse.data.username);
        setGitHubToken(githubResponse.data.githubAccessToken);
        setIsConnectedToGitHub(true);
        setIsLoggedIn(true);
      } catch (error) {
        if (error.response?.status === 401) {
          console.log('Unauthorized access - clearing token');
          localStorage.removeItem('token');
          setIsLoggedIn(false);
        } else if (error.response?.status === 404) {
          console.log('No GitHub details found for this user.');
          setIsConnectedToGitHub(false);
        } else {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [setIsLoggedIn]);

  // Fetch saved Jira credentials on component load
  useEffect(() => {
    const fetchJiraCredentials = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/jira/credentials', {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Set the state with fetched credentials
        const { jiraUsername, jiraApiToken, jiraDomain } = response.data;
        setJiraUsername(jiraUsername);
        setJiraApiToken(jiraApiToken);
        setJiraDomain(jiraDomain);
      } catch (error) {
        console.error('Error fetching Jira credentials:', error);
      }
    };

    fetchJiraCredentials();
  }, []);

  const handleSaveJiraDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        '/jira/save',
        { jiraUsername, jiraApiToken, jiraDomain },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert('Jira credentials saved successfully!');
    } catch (error) {
      console.error('Error saving Jira credentials:', error);
      alert('Failed to save Jira credentials.');
    }
  };

  // fetch repos
  useEffect(() => {
    const fetchRepos = async () => {
      if (isConnectedToGitHub) {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get('/github/repos', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setRepos(response.data);
        } catch (error) {
          console.error('Error fetching repositories:', error);
        }
      }
    };
    fetchRepos();
  }, [isConnectedToGitHub]);

  // Fetch cloned repos from S3
  useEffect(() => {
    const fetchClonedRepos = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/github/cloned-repos', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClonedRepos(response.data);
      } catch (error) {
        console.error('Error fetching cloned repositories:', error);
      }
    };
    fetchClonedRepos();
  }, []);

  useEffect(() => {
    const fetchCustomDomain = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/userurl/getCustomDomain', {
          headers: { Authorization: `Bearer ${token}` },
        });

        const { customDomain, dnsTarget, instructions } = response.data;

        setCustomDomain(customDomain);
        setDnsTarget(dnsTarget);
        setInstructions(instructions);
      } catch (error) {
        if (error.response?.status === 404) {
          console.log('No custom domain set for this user.');
        } else {
          console.error('Error fetching custom domain:', error);
        }
      }
    };

    fetchCustomDomain();
  }, []);


  const handleGitHubConnect = async () => {
    if (!githubUsername || !githubToken) {
      alert('Please enter your GitHub username and personal access token.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        '/github/connect',
        {
          username: githubUsername,
          accessToken: githubToken,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('GitHub connection response:', response.data);
      setIsConnectedToGitHub(true);
      setGitHubError('');
    } catch (error) {
      console.error('Error connecting to GitHub:', error);
      setGitHubError('Failed to connect to GitHub. Please check your credentials.');
    }
  };

  const handleCloneRepo = async () => {
    if (!selectedRepo) {
      alert('Please select a repository to clone.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        '/github/clone',
        {
          repoName: selectedRepo,
          branch,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert('Repository cloned successfully.');
      fetchClonedRepos();
    } catch (error) {
      console.error('Error cloning repository:', error);
      alert('Failed to clone repository.');
    }
  };

  const handleSaveGitHubDetails = async () => {
    if (!githubUsername || !githubToken) {
      alert('Please enter your GitHub username and personal access token.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        '/github/save',
        {
          username: githubUsername,
          accessToken: githubToken,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('GitHub details saved response:', response.data);
      alert('GitHub details saved successfully.');
    } catch (error) {
      console.error('Error saving GitHub details:', error);
      alert('Failed to save GitHub details. Please try again.');
    }
  };

  const handleSave = async () => {
    try {
      if (newPassword === repeatPassword) {
        const updateData = { email };

        if (newPassword) {
          updateData.password = newPassword;
        }

        // Include the token in the headers (retrieve it again from local storage)
        const updatedToken = localStorage.getItem('token') || '';
        const response = await axios.put(
          '/auth/me',
          updateData,
          {
            headers: {
              Authorization: `Bearer ${updatedToken}`,
            },
          }
        );

        console.log('Update Response:', response.data);

        // Update the 'password' state with the new password
        setPassword(newPassword);

        alert('Changes saved successfully!');
      } else {
        alert('New passwords do not match!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    // Reset the form fields
    setEmail('');
    setPassword('');
    setNewPassword('');
    setRepeatPassword('');
    setShowReset(false);
  };

  const fetchRepoFiles = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/github/${selectedClonedRepo}/files`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
      });
      console.log('Raw Tree Data from S3:', response.data);
      if (Array.isArray(response.data)) {
        setTreeData(response.data);
      } else {
        console.error('Unexpected data format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching repo files from S3:', error);
    }
  };

  const handleOpenModal = async () => {
    if (!selectedClonedRepo) {
      alert('Please select a cloned repository to view.');
      return;
    }
    setIsModalVisible(true);
    await fetchRepoFiles();
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleUpdateLinks = async () => {
    if (!selectedClonedRepo) {
      alert('Please select a cloned repository to update.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `/github/${selectedClonedRepo}/update-links`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert('Links updated successfully.');
    } catch (error) {
      console.error('Error updating links in repository:', error);
      alert('Failed to update links in repository.');
    }
  };

  // Handle membership selection
  const handleSelectMembership = async (type) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        '/auth/me',
        { membershipType: type },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setMembershipType(response.data.membershipType);
      setMembershipExpiration(response.data.membershipExpiration);
      message.success('Membership updated successfully!');
    } catch (error) {
      console.error('Error updating membership:', error);
      message.error('Failed to update membership.');
    }
  };

  // Handler for submitting membership details
  const handleMembershipSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        '/auth/me',
        {
          firstName,
          lastName,
          membershipType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert('Membership updated successfully!');
      setShowMembershipForm(false);
    } catch (error) {
      console.error('Error updating membership:', error);
      alert('Failed to update membership.');
    }
  };

  // Handler to set custom domain
  const handleSetCustomDomain = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        '/userurl/setCustomDomain',
        { customDomain },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDnsTarget(response.data.dnsTarget);
      setInstructions(response.data.instructions);
      alert('Custom domain set successfully!');
    } catch (error) {
      console.error('Error setting custom domain:', error);
      alert(error.response?.data?.message || 'Error setting custom domain');
    }
  };

  const contentMap = {
    '1': (
      <div className="account-settings-wrapper">
        <h2>Account Info</h2>
        <div className="account-settings-input">
          <label className="input-label">Email: </label>
          <Input 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
        </div>
        <div className="account-settings-input">
          <label className="input-label">Password:</label>
          <Input.Password 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
          <a onClick={() => setShowReset(true)}>Reset Password</a>
          {showReset && (
            <>
              <Input.Password placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
              <Input.Password placeholder="Repeat New Password" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} />
            </>
          )}
        </div>
        <div className="account-settings-buttons">
          <Button className="primary-button" onClick={handleSave}>Save</Button>
          <Button className="cancel-button" onClick={handleCancel}>Cancel</Button>
        </div>
      </div>
    ),
    '2': (
      <div className="membership-wrapper">
        <h2>Select a Membership Plan</h2>
        <div className="membership-options">
          {membershipOptions.map((option) => (
            <div
              className={`membership-option ${
                membershipType === option.type ? 'current' : ''
              }`}
              key={option.type}
            >
              <h3>{option.title}</h3>
              <p>{option.duration}</p>
              <ul>
                {option.features.map((feature) => (
                  <li key={feature}>{feature}</li>
                ))}
              </ul>
              {membershipType === option.type ? (
                <Button className="current-button" disabled>
                  Current
                </Button>
              ) : (
                <Button
                  className="primary-button"
                  onClick={() => handleSelectMembership(option.type)}
                >
                  Select
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
    ),
    '3': (
      <div className="github-details-wrapper">
        <h2>GitHub Integration</h2>
        <p>To integrate your GitHub account, add your GitHub Username and your GitHub Personal Access Token (can be generated in: Settings > Developer Settings > Personal Access Tokens > Tokens Classic > Generate new Token).</p>
        <div className="account-settings-input">
          <Input
            placeholder="GitHub Username"
            value={githubUsername}
            onChange={(e) => setGitHubUsername(e.target.value)}
          />
        </div>
        <div className="account-settings-input">
          <Input.Password
            placeholder="GitHub Personal Access Token"
            value={githubToken}
            onChange={(e) => setGitHubToken(e.target.value)}
          />
        </div>
        <div className="account-settings-buttons">
          <Button className="primary-button" onClick={handleGitHubConnect}>Connect to GitHub</Button>
          <Button className="primary-button" onClick={handleSaveGitHubDetails} >Save GitHub Details</Button>
        </div >
        {isConnectedToGitHub && (
          <div className="github-details-connected-repo">
            <div className="account-settings-select">
              <Select
                style={{ width: '100%', marginTop: '20px' }}
                placeholder="Select a repository to clone"
                onChange={(value, option) => setSelectedRepo(option.key)} // This ensures the full name (username/repoName) is used.
              >
                {repos.map(repo => (
                  <Option key={repo.full_name} value={repo.full_name}>
                    {repo.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="account-settings-input">
              <Input
                placeholder="Branch (default: main)"
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                style={{ marginTop: '10px' }}
              />
            </div>
            <div className="account-settings-buttons">
              <Button className="primary-button" onClick={handleCloneRepo} style={{ marginTop: '10px' }}>
                Clone Repo
              </Button>
            </div>
            <div className="account-settings-select">
              <Select
                style={{ width: '100%', marginTop: '20px' }}
                placeholder="Select a cloned repository to view"
                onChange={(value) => setSelectedClonedRepo(value)} // Sets the selected cloned repo
              >
                {clonedRepos.map(repo => (
                  <Option key={repo} value={repo}>
                    {repo}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="account-settings-buttons">
              <Button className="primary-button" onClick={handleUpdateLinks} style={{ marginTop: '10px' }}>
                Update Links in Repo
              </Button>
              <Button className="secondary-button" onClick={handleOpenModal} style={{ marginTop: '10px' }}>
                View Cloned Files
              </Button>
            </div>
          </div>
        )}
        {githubError && <p style={{ color: 'red' }}>{githubError}</p>}
      </div>
    ),
    '4': (
      <div className="UserDashbaord-url-container">
        <UserURLSettings />
        <div className="custom-domain-wrapper">
          <h2>Set Your Custom Domain</h2>
          <Input
            placeholder="Enter your custom domain (e.g., www.example.com)"
            value={customDomain}
            onChange={(e) => setCustomDomain(e.target.value)}
          />
          <Button className="primary-button" onClick={handleSetCustomDomain} style={{ marginTop: '10px' }}>
            Set Custom Domain
          </Button>
          {customDomain && dnsTarget && (
            <div>
              <p>{instructions}</p>
              <p>
                <strong>DNS Target:</strong> {dnsTarget}
              </p>
            </div>
          )}
        </div>
      </div>
    ),
    '5': (
      <div className="jira-integration-wrapper">
        <h2>Jira Integration</h2>
        <div className="account-settings-input">
          <Input 
            placeholder="Jira Domain" 
            value={jiraDomain} 
            onChange={(e) => setJiraDomain(e.target.value)} 
          />
        </div>
        <div className="account-settings-input"> 
          <Input 
            placeholder="Jira Username" 
            value={jiraUsername} 
            onChange={(e) => setJiraUsername(e.target.value)} 
          />
        </div>
        <div className="account-settings-input">  
          <Input.Password 
            placeholder="Jira API Token" 
            value={jiraApiToken} 
            onChange={(e) => setJiraApiToken(e.target.value)} 
          />
        </div>
        <div className="account-settings-buttons">
          <Button className="primary-button" onClick={handleSaveJiraDetails}>Save and Connect</Button>
        </div>
      </div>
    ),
  };

  return (
    <Layout className="UserDahsboardWrapper">
      <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <div className="UserDashboardContainer">
        <Sider className="userDashboardLeftSidebar">
          <div className="userDashboardLeftSideBarLabel">
            <p>My Account</p>
          </div>
          <Menu 
            mode="inline" 
            defaultSelectedKeys={['1']} 
            style={{ borderRight: 0 }}
            onSelect={({ key }) => setSelectedKey(key)}
          >
            <Menu.Item key="1">Account settings</Menu.Item>
            <Menu.Item key="2">Membership</Menu.Item>
            <Menu.Item key="3">GitHub integration</Menu.Item>
            <Menu.Item key="4">Hosting Url settings</Menu.Item>
            <Menu.Item key="5">Jira integration</Menu.Item>
          </Menu>
        </Sider>
        <Layout className="settings-content-area">
          <Content>
            <div className="user-dashboard-content-wrap">
              <h1>User Dashboard</h1>
              <div className="current-membership">
                <h3>Your Current Membership</h3>
                <p>Type: {membershipType}</p>
                {membershipExpiration && (
                  <p>
                    Expires on: {new Date(membershipExpiration).toLocaleDateString()}
                  </p>
                )}
              </div>
              {contentMap[selectedKey]}
            </div>
            <StorageUsage />
          </Content>
        </Layout>
      </div>
      <Modal
        title="Cloned Repository Files"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Tree
          treeData={treeData}
          defaultExpandAll
        />
      </Modal>
    </Layout>
  );
};

export default UserDashboard;
