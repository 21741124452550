// RightSidebar.js
import React, { useState } from 'react';
import { MenuOutlined, CloseOutlined, CopyOutlined, SendOutlined } from '@ant-design/icons';
import { ReactComponent as AiIcon } from '../assets/icons/ai-icon.svg';
import { Input, Button, message, Select } from 'antd';
import { callOpenAI } from '../utils/openai';
import axios from 'axios';
import { queryCodeEmbeddings } from '../utils/codeEmbeddings';

const { TextArea } = Input;

const models = [
  'gpt-4-turbo',
  'gpt-4',
  'gpt-4o',
  'gpt-3.5-turbo-16k',
  'gpt-3.5-turbo',
  'gpt-4-0613',
];

const RightSidebar = ({ }) => {
  const [prompt, setPrompt] = useState('');
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');
  const [selectedModel, setSelectedModel] = useState(models[0]);

  const handleSendPrompt = async () => {
    if (!prompt) {
      message.warning('Please enter a prompt.');
      return;
    }

    try {
      // Query code embeddings
      const codeResults = await queryCodeEmbeddings(prompt);

      // Prepare context
      let context = '';
      codeResults.forEach((result, index) => {
        context += `Relevant Code Snippet ${index + 1}:\n`;
        context += `File: ${result.filePath}\n`;
        context += `Function: ${result.functionName}\n`;
        context += `${result.codeSnippet}\n\n`;
      });

      const systemMessage = `You are an AI assistant helping with code generation and understanding. Use the provided code snippets to assist the user.`;

      // Prepare messages array
      const messages = [
        { role: 'system', content: systemMessage },
        { role: 'user', content: `Question: ${prompt}\n\n${context}` },
      ];

      // Call OpenAI API
      const result = await axios.post('/api/openai', { messages, model: selectedModel });

      // Extract the response content
      const aiResponse = result.data.choices[0].message.content;
      setResponse(aiResponse);
    } catch (error) {
      console.error('Error sending prompt:', error);
      message.error('Failed to get response from AI.');
    }
  };


  // Working function to just get a response from open ai gpt model based on users message.
  //const handleSendPrompt = async () => {
  //  if (!prompt) {
  //    message.warning('Please enter a prompt.');
  //    return;
  //  }

  //  try {
  //    const result = await axios.post('/api/openai', { prompt, model: selectedModel });
  //    setResponse(result.data.choices[0].message.content);
  //  } catch (error) {
  //    console.error('Error sending prompt:', error);
  //    message.error('Failed to get response from AI.');
  //  }
  //};
//
  return (
    <div className="CodeRightSidebar">
      <div className="ai-intro">
        <AiIcon />
        <h4>How can I help?</h4>
      </div>
      <div className="CodeRIghtSidebarContents">
        <div className="ai-response">
          <div className="ai-model-controls">
            <Select
              value={selectedModel}
              onChange={(value) => setSelectedModel(value)}
              style={{ width: '100%', marginBottom: '10px' }}
            >
              {models.map((model) => (
                <Select.Option key={model} value={model}>
                  {model}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="ai-response-contents">
            {response && (
              <div className="response-item">
                <h4>Response:</h4>
                <pre><p>{response}</p></pre>
              </div>
            )}
          </div>
        </div>
        <div className="ai-prompt">
          <TextArea
            rows={4}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt here..."
          />
          <Button type="primary" icon={<SendOutlined />} onClick={handleSendPrompt} style={{ marginTop: '10px' }}>
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;