// frontend/src/hooks/useTemplateDrop.js

import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PageContentContext } from '../contexts/PageContentContext';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import { getComponentProps } from './getComponentProps';
import { loadComponentFromS3, registerDynamicComponent } from '../components/DynamicComponentLoader';

/**
 * Custom hook to handle dropping templates into the PageContainer.
 * @param {Object} components - The shared components object from PageContainer.
 */
const useTemplateDrop = (components, designTokens) => {
  const { rows, setRows } = useContext(PageContentContext);
  const { nestedComponents, setNestedComponents } = useContext(NestedComponentsContext);

  const token = localStorage.getItem('token') || '';

  /**
   * Handler function for dropping a template.
   * @param {Event} event - The drop event.
   */
  const handleTemplateDrop = async (event) => {
    console.log('handleTemplateDrop: Event triggered');
    event.preventDefault();

    const data = event.dataTransfer.getData('application/json');
    console.log('handleTemplateDrop: Raw data from drag event:', data);

    if (!data) {
      console.error('handleTemplateDrop: No template data found in drop event.');
      return;
    }

    let dragData;
    try {
      dragData = JSON.parse(data);
      console.log('handleTemplateDrop: Parsed dragData:', dragData);
    } catch (error) {
      console.error('handleTemplateDrop: Error parsing template drag data:', error);
      return;
    }

    if (dragData.type !== 'template' || !dragData.rowData) {
      console.error('handleTemplateDrop: Dragged data is not a valid template.', dragData);
      return;
    }

    const { rowData } = dragData;
    console.log('handleTemplateDrop: Extracted rowData:', rowData);

    if (!rowData.columns || !Array.isArray(rowData.columns)) {
      console.error('handleTemplateDrop: Invalid rowData structure: "columns" is missing or not an array.', rowData);
      return;
    }

    const processedColumnsPromises = rowData.columns.map(async (column, colIndexTemplate) => {
      const columnSize = column.columnSize || 1;
      const columnComponents = Array.isArray(column.components) ? column.components : [];

      const processedComponentsPromises = columnComponents.map(async (component) => {
        const { name: componentName, source: componentSource, tokens: componentTokens, props: componentProps } = component;

        if (!componentName) {
          console.error('Component name is missing in the template data.');
          return null;
        }

        let ComponentModule = components[componentName]?.component;

        if (!ComponentModule) {
          console.log(`handleTemplateDrop: Component "${componentName}" is not loaded. Attempting to load.`);
          if (componentSource === 's3') {
            ComponentModule = await loadComponentFromS3(componentName, token);
            if (ComponentModule) {
              registerDynamicComponent(components, componentName, ComponentModule, designTokens);
              console.log(`handleTemplateDrop: Component "${componentName}" loaded and registered from S3.`);
            } else {
              console.error(`Failed to load component "${componentName}" from S3.`);
              return null;
            }
          } else if (componentSource === 'local') {
            if (typeof components[componentName] === 'function') {
              const module = await components[componentName]();
              ComponentModule = module.default || module;
              components[componentName] = { component: ComponentModule };
              console.log(`handleTemplateDrop: Component "${componentName}" loaded locally.`);
            } else {
              console.error(`Component "${componentName}" not found in local imports.`);
              return null;
            }
          } else {
            console.error(`Unknown component source: "${componentSource}"`);
            return null;
          }
        }

        const newComponentId = uuidv4();
        const newNestedComponents = (component.nestedComponents || []).map((nestedComp) => ({
          ...nestedComp,
          id: uuidv4(),
        }));

        if (newNestedComponents.length) {
          setNestedComponents((prev) => ({
            ...prev,
            [newComponentId]: newNestedComponents,
          }));
        }

        return {
          id: newComponentId,
          name: componentName,
          component: ComponentModule,
          source: componentSource,
          s3ComponentName: componentSource === 's3' ? componentName : undefined,
          props: componentProps || getComponentProps(ComponentModule),
          tokens: componentTokens || {},
          nestedComponents: newNestedComponents,
        };
      });

      const processedComponents = await Promise.all(processedComponentsPromises);
      const validComponents = processedComponents.filter((comp) => comp !== null);

      return {
        columnSize,
        components: validComponents,
      };
    });

    const processedColumns = await Promise.all(processedColumnsPromises);

    const newRow = {
      columns: processedColumns.map((col) => col.columnSize),
      components: processedColumns.map((col) => col.components),
    };

    setRows((prevRows) => [...prevRows, newRow]);
    console.log('handleTemplateDrop: Template dropped and rows added successfully.');
  };

  return { handleTemplateDrop };
};

export default useTemplateDrop;
