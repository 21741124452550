// frontennd/src/components/gitHub/GitHubFileView.js
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import axios from 'axios';

const GitHubFileView = ({ visible, onClose, fileKey, selectedRepo }) => {
  const [fileContent, setFileContent] = useState('');
  const [viewMode, setViewMode] = useState('code'); // 'code' or 'visual'
  const [adjustedContent, setAdjustedContent] = useState('');

  useEffect(() => {
    if (visible && fileKey && selectedRepo) {
      fetchFileContent();
    }
  }, [visible, fileKey, selectedRepo]);

  const fetchFileContent = async () => {
    try {
      const token = localStorage.getItem('token');
      const encodedFileKey = encodeURIComponent(fileKey); // Properly encode the file key

      const response = await axios.get(`/github/${selectedRepo}/file-content`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { key: encodedFileKey }, // Send the encoded file key as a query parameter
      });

      if (response.data.content) {
        const content = atob(response.data.content); // Decode base64 content
        setFileContent(content);
        adjustResourcePaths(content);
      } else {
        console.error('Unexpected file content response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching file content:', error);
    }
  };

  const adjustResourcePaths = (content) => {
    // Adjust paths for images, videos, CSS, JS, etc.
    //const baseUrl = `https://github-repos-storage.s3.${process.env.AWS_REGION}.amazonaws.com/${selectedRepo}/`;
    const baseUrl = `https://${BUCKET_NAME}.s3.${process.env.AWS_REGION}.amazonaws.com/${selectedRepo}/`;
    //const baseUrl = `https://github-repos-storage.s3.eu-north-1.amazonaws.com/${selectedRepo}/`;
    const adjustedContent = content.replace(/(src|href)=["']([^"']+)["']/g, (match, p1, p2) => {
      // Ignore internal anchor links (e.g., href="#id")
      if (p2.startsWith('#')) {
        return match;
      }

      // For relative paths, prepend S3 URL
      if (!p2.startsWith('http') && !p2.startsWith('//')) {
        const newUrl = `${baseUrl}${p2}`;
        return `${p1}="${newUrl}"`;
      }

      return match;
    });
    setAdjustedContent(adjustedContent);
  };

  const renderVisualView = () => {
    // Create a blob URL to safely inject the HTML into an iframe
    const blob = new Blob([adjustedContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);

    return <iframe src={url} title="Visual View" style={{ width: '100%', height: '600px', border: 'none' }} sandbox="allow-same-origin allow-scripts" />;
  };

  return (
    <Modal
      title={`Viewing File: ${fileKey}`}
      visible={visible}
      onCancel={onClose}
      onOk={onClose}
      width="80%"
      footer={null}
    >
      <div style={{ marginBottom: '10px' }}>
        <Button
          type={viewMode === 'code' ? 'primary' : 'default'}
          onClick={() => setViewMode('code')}
          style={{ marginRight: '10px' }}
        >
          Code View
        </Button>
        <Button
          type={viewMode === 'visual' ? 'primary' : 'default'}
          onClick={() => setViewMode('visual')}
        >
          Visual View
        </Button>
      </div>

      {viewMode === 'code' ? (
        <pre>{fileContent}</pre>
      ) : (
        renderVisualView()
      )}
    </Modal>
  );
};

export default GitHubFileView;
