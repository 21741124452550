// frontend/src/components/membershipOptions.js
export const membershipOptions = [
  {
    type: 'trial',
    title: 'Trial',
    duration: '7-day access',
    features: ['Basic features'],
  },
  {
    type: 'starter',
    title: 'Starter',
    duration: '30-day access',
    features: ['Starter features'],
  },
  {
    type: 'professional',
    title: 'Professional',
    duration: '1-year access',
    features: ['All features'],
  },
];
