// frontend/src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import axios from 'axios';

// Set up Axios interceptors globally
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      if (error.response.data.message === 'Your membership has expired.') {
        // Handle membership expiration globally
        console.log('Membership has expired. Redirect or notify the user.');
        // Optionally update global state or redirect
        // Example: window.location.href = '/';
      }
    }
    return Promise.reject(error);
  }
);

// Expose React and ReactDOM globally
window.React = React;
window.ReactDOM = ReactDOM;

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

const renderApp = async () => {
  try {
    const pathParts = window.location.pathname.split('/').filter(part => part !== '');
    const [firstPart] = pathParts;

    if (!['dashboard', 'uibuilder', 'media', 'CodeEditor'].includes(firstPart)) {
      // Check if the hostname is a custom domain
      const response = await axios.get('/userurl/getByHostname', { withCredentials: true });
      const { userId, userurl } = response.data;

      const { default: PublicApp } = await import('./PublicApp');
      root.render(
        <React.StrictMode>
          <PublicApp userId={userId} userurl={userurl} pageName="" />
        </React.StrictMode>
      );
    } else {
      // Render the regular app
      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      );
    }
  } catch (error) {
    console.error('Error determining app type:', error);
    // Fallback to URL path parsing if hostname check fails
    const pathParts = window.location.pathname.split('/').filter(part => part !== '');
    const [userId, userurl, pageName] = pathParts;

    if (userId && userurl) {
      const { default: PublicApp } = await import('./PublicApp');
      root.render(
        <React.StrictMode>
          <PublicApp userId={userId} userurl={userurl} pageName={pageName} />
        </React.StrictMode>
      );
    } else {
      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      );
    }
  }
};

// Show a loader initially
root.render(<div className="app-loader">Loading...</div>);

// Trigger the app rendering
renderApp();
