import React, { useState } from 'react';
import axios from 'axios';
import { message } from 'antd';

const Register = ({ switchToLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const registerResponse = await axios.post('https://www.webbify.io/auth/register', { 
        email, 
        password, 
        username: email, 
      });

      message.success('Registration successful! Please log in.'); // Display success message
      // Handle successful registration or login, e.g., show a success message
      //console.log('User registered successfully:', registerResponse.data);
      // Switch to login tab on successful registration
      if (switchToLogin) {
        switchToLogin();
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setError(error.response?.data?.message || 'An error occurred during registration.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2>Register</h2>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Registering...' : 'Register'}
        </button>
      </form>
    </div>
  );
};

export default Register;
