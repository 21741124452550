import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const CustomCSSPanel = ({
  selectedComponentInfo,
  editedTokens,
  designTokens,
  setEditedTokens,
  setTokenNames,
  tokenNames,
  viewportSize,
  updateDesignTokens, // Add this prop
}) => {
  if (!selectedComponentInfo || !selectedComponentInfo.id) return null;

  const componentId = selectedComponentInfo.id;

  // State to manage local input without triggering immediate re-renders
  const [localValues, setLocalValues] = useState({ customCSS: '' });
  const [originalValues, setOriginalValues] = useState({}); // Store original values

  // Initialize localValues and originalValues with existing tokens or props
  useEffect(() => {
    const componentTokens =
      editedTokens[componentId]?.[viewportSize] ||
      designTokens[componentId]?.[viewportSize] ||
      selectedComponentInfo.tokens?.[viewportSize] ||
      {};

    const initialCSS = componentTokens['customCSS'] || '';
    setLocalValues({ customCSS: initialCSS });
    setOriginalValues(componentTokens); // Store the original tokens
  }, [componentId, viewportSize, editedTokens, designTokens, selectedComponentInfo.tokens]);

  const parseCustomCSS = (cssString) => {
    const styles = {};
    cssString.split(';').forEach((rule) => {
      const [property, value] = rule.split(':').map((s) => s.trim());
      if (property && value) {
        // Convert CSS property to camelCase to match token keys
        const camelCaseProperty = property.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
        styles[camelCaseProperty] = value;
      }
    });
    return styles;
  };

  const handleBlurOrEnter = (event) => {
    if (event.type === 'blur' || event.key === 'Enter') {
      const cssString = localValues['customCSS'] || '';
      console.log(`Blur or Enter detected for customCSS, processing value: "${cssString}"`);

      if (!cssString.trim()) {
        // If input is empty, revert to original tokens
        console.log('Reverting to original values:', originalValues);
        setEditedTokens((prevTokens) => ({
          ...prevTokens,
          [componentId]: {
            ...(prevTokens[componentId] || {}),
            [viewportSize]: {
              ...(prevTokens[componentId]?.[viewportSize] || {}),
              ...originalValues, // Revert to original values
            },
          },
        }));
        updateDesignTokens(componentId, originalValues, viewportSize);
        return;
      }

      // Parse the CSS into individual styles
      const styles = parseCustomCSS(cssString);

      // Include the customCSS token itself
      styles['customCSS'] = cssString;

      // Update editedTokens directly
      setEditedTokens((prevTokens) => ({
        ...prevTokens,
        [componentId]: {
          ...(prevTokens[componentId] || {}),
          [viewportSize]: {
            ...(prevTokens[componentId]?.[viewportSize] || {}),
            ...styles,
          },
        },
      }));

      // Clear token names for the properties set
      setTokenNames((prevTokenNames) => {
        const updatedTokenNames = { ...prevTokenNames };
        Object.keys(styles).forEach((propertyType) => {
          updatedTokenNames[componentId] = {
            ...prevTokenNames[componentId],
            [propertyType]: '',
          };
        });
        return updatedTokenNames;
      });

      // Update design tokens directly
      updateDesignTokens(componentId, styles, viewportSize);
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setLocalValues({ customCSS: newValue });
  };

  const clearCSS = () => {
    setLocalValues({ customCSS: '' });
    // Prepare tokens to update
    const tokensToUpdate = { customCSS: '' };
    // Update editedTokens directly
    setEditedTokens((prevTokens) => ({
      ...prevTokens,
      [componentId]: {
        ...(prevTokens[componentId] || {}),
        [viewportSize]: {
          ...(prevTokens[componentId]?.[viewportSize] || {}),
          ...tokensToUpdate,
        },
      },
    }));
    // Clear token names
    setTokenNames((prevTokenNames) => ({
      ...prevTokenNames,
      [componentId]: {
        ...prevTokenNames[componentId],
        customCSS: '',
      },
    }));
    // Update design tokens directly
    updateDesignTokens(componentId, tokensToUpdate, viewportSize);
  };

  return (
    <div className="custom-css-panel">
      <label>Custom CSS</label>
      <div style={{ position: 'relative' }}>
        <Input.TextArea
          rows={4}
          value={localValues['customCSS']}
          placeholder="Enter custom CSS (e.g., height: 100px;)"
          onChange={handleInputChange}
          onBlur={handleBlurOrEnter}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleBlurOrEnter(e);
            }
          }}
          style={{ resize: 'none' }}
        />
        <Button
          icon={<CloseOutlined />}
          onClick={clearCSS}
          style={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            zIndex: 1,
          }}
        />
      </div>
    </div>
  );
};

export default CustomCSSPanel;
