// frontend/src/components/TemplatesSidebar.js

import React, { useEffect, useState, useContext } from 'react';
import { Collapse, Button, Input, Select, Modal, Form, message } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { TemplateContext } from '../contexts/TemplateContext';

const { Panel } = Collapse;
const { Option } = Select;

const TemplatesSidebar = ({ token }) => {
  const [templates, setTemplates] = useState([]);
  const [panels, setPanels] = useState(['Pages', 'Components']); // Default panels
  const { isModalVisible, selectedRowData, closeTemplateModal } = useContext(TemplateContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [form] = Form.useForm();

  // Fetch templates from backend
  const fetchTemplates = async () => {
    try {
      const res = await axios.get('/templates', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTemplates(res.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
      message.error('Failed to fetch templates.');
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  // Add console logs to monitor when the modal is rendered
  useEffect(() => {
    if (isModalVisible) {
      console.log('TemplatesSidebar: isModalVisible:', isModalVisible);
      console.log('TemplatesSidebar: selectedRowData:', selectedRowData);
    } else {
      console.log('TemplatesSidebar: Modal is now hidden');
    }
  }, [isModalVisible, selectedRowData]);

  // Get unique panel names from templates or user-defined panels
  const getPanelNames = () => {
    return [...new Set(templates.map(template => template.panelName))];
  };

  // Handle adding a new panel
  const handleAddPanel = () => {
    let newPanelName = prompt("Enter new panel name:");
    if (newPanelName) {
      newPanelName = newPanelName.trim();
      if (!newPanelName) {
        message.error('Panel name cannot be empty.');
        return;
      }
      if (panels.includes(newPanelName)) {
        message.error('Panel name already exists.');
        return;
      }
      setPanels([...panels, newPanelName]);
    }
  };

  // Handle creating a new template
  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      const { panelName, templateName } = values;

      // Prepare the data to send
      const newTemplate = {
        panelName,
        templateName,
        rowData: {
          columns: selectedRowData.columns, // [1, 2, ...]
          components: selectedRowData.components, // [[comp1], [comp2, comp3], ...]
        },
      };

      // Send POST request to create template
      const res = await axios.post('/templates', newTemplate, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setTemplates([...templates, res.data]);
      message.success('Template created successfully.');
      form.resetFields();
      closeTemplateModal();
    } catch (error) {
      console.error('Error creating template:', error);
      // Enhanced error handling as previously discussed
    }
  };

  const handleModalCancel = () => {
    form.resetFields();
    closeTemplateModal();
  };

  // Handle deleting a template
  const handleDeleteTemplate = async (templateId) => {
    try {
      await axios.delete(`/templates/${templateId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTemplates(templates.filter(template => template._id !== templateId));
      message.success('Template deleted successfully.');
    } catch (error) {
      console.error('Error deleting template:', error);
      message.error('Failed to delete template.');
    }
  };

  // Handle dragging a template
  const handleTemplateDragStart = (event, template) => {
    event.dataTransfer.setData('application/json', JSON.stringify({
      type: 'template',
      rowData: template.rowData,
    }));
    // Optionally, set a drag image
    if (event.dataTransfer.setDragImage) {
      const img = new Image();
      img.src = 'path_to_placeholder_image'; // Optional: path to a placeholder image
      event.dataTransfer.setDragImage(img, 0, 0);
    }
  };

  return (
    <div className="templates-sidebar">
      <div className="templates-header">
        <h3>Templates</h3>
        <Button type="dashed" onClick={handleAddPanel} icon={<PlusOutlined />}>
          Add Panel
        </Button>
      </div>
      <Collapse accordion>
        {panels.map((panel, index) => (
          <Panel header={panel} key={index}>
            {templates.filter(template => template.panelName === panel).length > 0 ? (
              templates.filter(template => template.panelName === panel).map(template => (
                <div 
                  key={template._id} 
                  className="template-item"
                  draggable 
                  onDragStart={(e) => handleTemplateDragStart(e, template)}
                  style={{ cursor: 'grab', padding: '8px', border: '1px solid #ccc', marginBottom: '4px', borderRadius: '4px' }}
                  aria-label={`Template ${template.templateName}`}
                >
                  <span>{template.templateName}</span>
                  <DeleteOutlined 
                    onClick={() => handleDeleteTemplate(template._id)} 
                    style={{ color: 'red', marginLeft: '10px', cursor: 'pointer' }}
                  />
                </div>
              ))
            ) : (
              <p>No templates in this panel.</p>
            )}
          </Panel>
        ))}
      </Collapse>

      {/* Modal for creating a new template */}      
      <Modal
        title="Create Template"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Create Template"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="panelName"
            label="Select Panel"
            rules={[{ required: true, message: 'Please select a panel.' }]}
          >
            <Select placeholder="Select a panel">
              {panels.map((panel, index) => (
                <Option key={index} value={panel}>
                  {panel}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="templateName"
            label="Template Name"
            rules={[{ required: true, message: 'Please enter a template name.' }]}
          >
            <Input placeholder="Enter template name" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TemplatesSidebar;
