// frontend/src/hooks/useComponentClick.js

import { useContext } from 'react';
import { BuilderContext } from '../contexts/BuilderContext';
import { TokensContext } from '../contexts/TokensContext';
import { ComponentPropsContext } from '../contexts/ComponentPropsContext';
import { SelectedComponentContext } from '../contexts/SelectedComponentContext';

const useComponentClick = () => {
  const isBuilder = useContext(BuilderContext);
  if (!isBuilder) {
    return () => {}; // Return a no-op function if not in builder mode
  }
  const { designTokens, editedTokens } = useContext(TokensContext);
  const { selectedComponentProps, updateComponentProps } = useContext(ComponentPropsContext);
  const { setSelectedComponentInfo } = useContext(SelectedComponentContext);
  const viewportSize = 'large'; // or retrieve from context if needed

  const handleComponentClick = (component) => {
    if (!component.id) {
      console.error('Component ID is undefined:', component);
      return;
    }

    const tokens = {
      ...component.tokens?.[viewportSize],
      ...designTokens[component.id]?.[viewportSize],
      ...editedTokens[component.id]?.[viewportSize],
    };
    const componentProps = selectedComponentProps[component.id] || component.props || {};

    if (!selectedComponentProps[component.id]) {
      updateComponentProps(component.id, componentProps);
    }

    setSelectedComponentInfo({
      id: component.id,
      name: component.name,
      props: componentProps,
      tokens: tokens,
    });
  };

  return handleComponentClick;
};

export default useComponentClick;
