// frontend/src/actions/selectActions.js

export const handleSelectActions = (actionDetails, executeAction) => {
  const { componentName, rowIndex, columnIndex } = actionDetails;

  if (!componentName) {
    alert('Component name is required to select.');
    return;
  }

  executeAction({
    type: 'select',
    selectComponent: true,
    componentName,
    rowIndex,
    columnIndex,
  });
};
