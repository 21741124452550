// frontend/src/contexts/NestedComponentsContext.js
import React, { createContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const NestedComponentsContext = createContext();

export const NestedComponentsProvider = ({ children, initialNestedComponents = {} }) => {
  const [nestedComponents, setNestedComponents] = useState(initialNestedComponents);

  useEffect(() => {
    console.log('NestedComponentsContext: updated nestedComponents', nestedComponents);
  }, [nestedComponents]);

  const addNestedComponent = (containerId, columnId, component) => {
    const newComponentWithId = {
      ...component,
      id: component.id || uuidv4(),
      props: component.props || {},
      tokens: component.tokens || {},
    };
    setNestedComponents((prevState) => {
      const updatedState = {
        ...prevState,
        [containerId]: {
          ...(prevState[containerId] || {}),
          [columnId]: [
            ...(Array.isArray(prevState[containerId]?.[columnId]) ? prevState[containerId][columnId] : []),
            newComponentWithId,
          ],
        },
      };
      return updatedState;
    });
  };

  return (
    <NestedComponentsContext.Provider
      value={{
        nestedComponents,
        setNestedComponents,
        addNestedComponent,
      }}
    >
      {children}
    </NestedComponentsContext.Provider>
  );
};
