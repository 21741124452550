// frontend/src/components/MembershipModal.js

import React, { useState } from 'react';
import { Modal, Button, Card, message } from 'antd';
import axios from 'axios';
import { membershipOptions } from './membershipOptions';

const MembershipModal = ({ setMembershipExpired, refreshUserData }) => {
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSelectMembership = (type) => {
    setSelectedMembership(type);
  };

  const handleUpgrade = async () => {
    if (!selectedMembership) {
      message.warning('Please select a membership option.');
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      // Update user's membership
      await axios.put(
        '/auth/me',
        {
          membershipType: selectedMembership,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success('Membership updated successfully!');
      await refreshUserData(); // Refresh user data and membership status
      setMembershipExpired(false);
    } catch (error) {
      console.error('Error updating membership:', error);
      message.error('Failed to update membership.');
    }
    setLoading(false);
  };

  return (
    <Modal
      visible={true}
      closable={false}
      footer={null}
      width={800}
      centered
      bodyStyle={{ padding: '40px' }}
    >
      <h2 style={{ textAlign: 'center' }}>Your membership has expired</h2>
      <p style={{ textAlign: 'center' }}>Please select a membership plan to continue using the app.</p>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        {membershipOptions.map((option) => (
          <Card
            key={option.type}
            title={option.title}
            bordered={selectedMembership === option.type}
            onClick={() => handleSelectMembership(option.type)}
            style={{
              width: 240,
              cursor: 'pointer',
              borderColor: selectedMembership === option.type ? '#1890ff' : '#f0f0f0',
            }}
          >
            <p>{option.duration}</p>
            <ul>
              {option.features.map((feature) => (
                <li key={feature}>{feature}</li>
              ))}
            </ul>
          </Card>
        ))}
      </div>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button type="primary" onClick={handleUpgrade} loading={loading}>
          Upgrade
        </Button>
      </div>
    </Modal>
  );
};

export default MembershipModal;
