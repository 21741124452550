// frontend/src/hooks/usePageManagement.js 
import { useState, useEffect, useContext } from 'react';
import { PageContentContext } from '../contexts/PageContentContext';
import { generateUniquePageId, verifyUniquePageIds } from '../utils/pageUtils';
import { TokensContext } from '../contexts/TokensContext';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import useSavePageContent from './useSavePageContent';

const usePageManagement = (
  components, 
  globalTokens,
  designTokens, 
  editedTokens, 
  selectedComponentInfo, 
  setSelectedComponentInfo, 
  token,
  fetchedPages = []
) => {
  const [pages, setPages] = useState([]);
  const [activePage, setActivePage] = useState(null);
  const { setPageContent, setRows } = useContext(PageContentContext);
  const { setNestedComponents } = useContext(NestedComponentsContext);
  const { setEditedTokens } = useContext(TokensContext);
  const savePageContent = useSavePageContent(token, pages, setPages, activePage, components, designTokens, selectedComponentInfo, setSelectedComponentInfo, editedTokens, setEditedTokens);

  // Fetching pages only on the first load and on manual save
  useEffect(() => {
    console.log('usePageManagement: Fetch pages useEffect triggered');
    console.log('Dependencies:', { fetchedPages });

    if (fetchedPages.length > 0) {
      setPages(fetchedPages);
      console.log('Pages set from fetchedPages:', fetchedPages);

      if (!activePage) {
        setActivePage(fetchedPages[0]?.pageId); // Automatically set active page if none is active
        console.log('Active page set to first fetched page:', fetchedPages[0]?.pageId);
      }
    }
  }, [fetchedPages]);
  // Comments:
  // - Loads pages on the first render if `fetchedPages` are provided.
  // - Sets the active page to the first available fetched page.

  // Handle switching between pages and updating rows only when a page is manually selected
  useEffect(() => {
    console.log('usePageManagement: Update rows on page switch useEffect triggered');
    console.log('Dependencies:', { activePage, pages });

    if (activePage) {
      const activePageContent = pages.find(page => page.pageId === activePage);
      console.log('Active Page Content:', activePageContent);
      
      if (activePageContent && activePageContent.content && activePageContent.content.props) {
        setRows(activePageContent.content.props.rows); // Update rows on page switch
        console.log('Switching to page:', activePage, 'with rows:', activePageContent.content.props.rows);

        // Add this block to update nestedComponents
        if (activePageContent.content.props.nestedComponents) {
          setNestedComponents(activePageContent.content.props.nestedComponents);
          console.log('Nested components updated for active page:', activePageContent.content.props.nestedComponents);
        } else {
          setNestedComponents({});
          console.log('Switching to page:', activePage, 'with no nestedComponents.');
        }
      }
    }
  }, [activePage, pages, setRows, setNestedComponents]);

  // Verify page IDs and prevent fetching again on every interaction
  useEffect(() => {
    verifyUniquePageIds(pages);
  }, [pages]);

  return { pages, setPages, activePage, setActivePage };
};

export default usePageManagement;
