import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './header/Header.css';
import { Modal, Tabs } from 'antd';
import Register from './Register';
import Login from './Login';
import Logo from '../assets/webbify-logo.svg';
import LogoWhite from '../assets/webbify-logo2.svg';

const { TabPane } = Tabs;

const Header = ({ isLoggedIn, setIsLoggedIn }) => {
  const [visible, setVisible] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeTab, setActiveTab] = useState('login');
  const navigate = useNavigate();

  //const showModal = () => {
  //  setVisible(true);
  //};
  const showModal = () => {
    if (!isLoggedIn) {
      setVisible(true);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
    navigate('/');
    setVisible(false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    navigate('/');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  //useEffect(() => {
  //  if (isLoggedIn === false) {
  //    setVisible(true); // Show modal only when isLoggedIn is explicitly false
  //  } else {
  //    setVisible(false); // Hide modal when isLoggedIn is true or null
  //  }
  //}, [isLoggedIn]);
  

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) { // Adjust this value to control when the class is added
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const switchToLogin = () => {
    setActiveTab('login');
  };

  return (
    <header aria-label="Main website Header with navigation links" className={scrolled ? 'scrolled' : ''}>
      <div className="logo" aria-label="Logo navigates to home page">
        <Link to="/" className="logoLink">
          <img className="defaultLogo" src={Logo} alt="Webbify Logo" />
          <img className="whiteLogo" src={LogoWhite} alt="Webbify Logo" />
        </Link>
      </div>
      <ul aria-label="Header navigation links" className={`header-links ${menuOpen ? 'open' : ''}`}>
        {/* Ensure all elements are wrapped correctly */}
        {isLoggedIn && (
          <>
            <li><Link aria-label="Link to UiBuilder" to="/uibuilder">Visual Editor</Link></li>
            <li><Link aria-label="Link to CodeEditor" to="/CodeEditor">Code Editor</Link></li>
            <li><Link aria-label="Link to Media" to="/media">Media</Link></li>
            <li><Link aria-label="Link to Analytics and Testing" to="/analytics-testing">Analytics & Testing</Link></li>
          </>
        )}
      </ul>
      <div aria-label="Header Account settings and Logout" className="header-right-actions">
        {isLoggedIn && (
          <>
            <li><Link aria-label="Link to Account Settings" to="/dashboard">Settings</Link></li>
          </>
        )}
        {isLoggedIn ? (
          <button aria-label="Button to Log out" onClick={handleLogout}>Logout</button>
        ) : (
          <button aria-label="Button opens Log in modal" onClick={showModal}>Log in</button>
        )}
        <Modal
          title="Log in"
          visible={visible}
          onCancel={handleCancel}
          footer={null}
          aria-label="Log in Modal"
        >
          <Tabs aria-label="Modal Tabs for Log in and Register" activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
            <TabPane tab="Login" key="login" aria-label="Log in Tab">
              <Login 
                setIsLoggedIn={() => {
                  setIsLoggedIn(true);
                  setVisible(false);
                }}
              />
            </TabPane>
            <TabPane aria-label="Register Tab" tab="Register" key="register">
              <Register switchToLogin={switchToLogin} />
            </TabPane>
          </Tabs>
        </Modal>
      </div>

      {/* Hamburger Icon */}
      <div aria-label="Navigation menu icon" className="hamburger" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {/* Fullscreen Menu */}
      <div className={`fullscreen-menu ${menuOpen ? 'show' : ''}`}>
        <div aria-label="Close menu button" className="close-icon" onClick={toggleMenu}>
          &times;
        </div>
        <ul aria-label="List of navigation items">
          {isLoggedIn && (
            <>
              <li><Link aria-label="Link to Homepage" to="/" onClick={toggleMenu}>Home</Link></li>
              <li><Link aria-label="Link to UiBuilder" to="/uibuilder" onClick={toggleMenu}>Visual Editor</Link></li>
              <li><Link aria-label="Link to CodeEditor" to="/CodeEditor" onClick={toggleMenu}>Code Editor</Link></li>
              <li><Link aria-label="Link to Media" to="/media" onClick={toggleMenu}>Media</Link></li>
              <li><Link aria-label="Link to Analytics and testing" to="#" onClick={toggleMenu}>Analytics & Testing</Link></li>
              <li><Link aria-label="Link to Settings" to="/dashboard">Settings</Link></li>
            </>
          )}
          <li>
            {isLoggedIn ? (
              <button aria-label="Button to logout" onClick={() => { handleLogout(); toggleMenu(); }}>Logout</button>
            ) : (
              <button aria-label="Button to show Log in Modal" onClick={() => { showModal(); toggleMenu(); }}>Log in</button>
            )}
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
